import * as React from 'react';
import { AppBar } from 'react-admin';
// import Typography from '@mui/material/Typography';
import { MenuItemLink, Link } from 'react-admin';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PercentIcon from '@mui/icons-material/Percent';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import InventoryIcon from '@mui/icons-material/Inventory';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from "@mui/material/Badge"
// import styled from "styled-components";
// import logo from '../assets/logo512.png'
import Logo from './Logo';
import UserService from '../UserService';

const MyAppBar = (props) => (
    
    <AppBar 
        sx={{
            backgroundColor: "#BF2279",
            minHeight: 55,
            "& .RaAppBar-title": {
                // flex: 1,
                // textOverflow: "ellipsis",
                // whiteSpace: "nowrap",
                // overflow: "hidden",
                display: "none"
            },
            "& .RaAppBar-menuButton": {
                paddingLeft: 15
            },
            "& .RaMenuItemLink-active ": {
                borderBottom: "1px solid #fff"
            },
            "& .MuiButtonBase-root:hover:active::after": {
                opacity: 0
            }
        }}
        {...props}
    >
        
        <Logo />
        
      <div className='top-menu-list'>
        <MenuItemLink to="/stores" primaryText="Stores" leftIcon={<StorefrontIcon />}
            sx={{
                color: "#fff",
                marginLeft: 30,
                fontSize: 14,
                "& .RaMenuItemLink-icon": {
                    color: "#fff",
                    minWidth: 28
                },
                "& .RaMenuItemLink-active": {
                    color: "#fff !important",

                }

            }}
        />
        <MenuItemLink to="/offers" primaryText="Offers" leftIcon={<PercentIcon />}
            sx={{
                color: "#fff",
                fontSize: 14,
                "& .RaMenuItemLink-icon": {
                    color: "#fff",
                    minWidth: 28
                }
            }} />
        <MenuItemLink to="/categories" primaryText="Categories" leftIcon={<FilterNoneIcon />}
            sx={{
                color: "#fff",
                fontSize: 14,
                "& .RaMenuItemLink-icon": {
                    color: "#fff",
                    minWidth: 28
                }
            }}
        />
        <MenuItemLink to="/deliveryCompany" primaryText="Delivery Services" leftIcon={<InventoryIcon />}
            sx={{
                color: "#fff",
                fontSize: 14,
                marginRight: 30,
                "& .RaMenuItemLink-icon": {
                    color: "#fff",
                    minWidth: 28
                }
            }}
        />
         </div>
         <div className='auth-section-wrapper'>

        <div className='auth-section'>
            <button onClick={UserService.doLogout}>Logout</button></div>
        <Link to="/notifications" className='notifications-link'> 
        <Badge color="secondary" variant="dot">
            <NotificationsNoneIcon />
        </Badge>
        </Link>
         </div>



        {/* <Typography
            variant="h6"
            color="inherit"
            // className={classes.title}
            id="react-admin-title"
        /> */}
        {/* <img src={logo} alt="logo" /> */}
        {/* <span className={classes.spacer} /> */}
    </AppBar>

);




export default MyAppBar;