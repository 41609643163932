import React, { useState, useEffect } from "react";
import { Create, SimpleForm, TextInput, useCreate, AutocompleteInput } from "react-admin";
import Wrapper from '../styles/scraping/ScrapingCreate'
import modal from "../../assets/modal.png";
import UserService from "../../UserService";
import { stores_url, fields_url, elements_url } from "../../constants";


const ScrapingCreate = (props) => {

  const [elements, setElements] = useState([]);
  const [fields, setFields] = useState([]);
  const [stores, setStores] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(elements_url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "authorization": "Bearer " + UserService.getToken()
          },
        });
        const json = await response?.json();
        let results = json;

        setElements(results);

      } catch (error) {
        console.log("error", error)
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(fields_url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "authorization": "Bearer " + UserService.getToken()
          },
        });
        const json = await response?.json();
        let resultsFields = json;

        setFields(resultsFields);

      } catch (error) {
        console.log("error", error)
      }
    }
    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(stores_url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "authorization": "Bearer " + UserService.getToken()
          },
        });
        const json = await response?.json();
        let results = json;
        setStores(results);
      } catch (error) {
        console.log("error", error)
      }
    }
    fetchData();
  }, []);

  const storesArray = stores




  const [create] = useCreate();
  const postSave = (data) => {
    console.log(data);
    create("scrapingRules/all", { data });
    // notify(`Changes saved`);
    props.onAddStore();
  };
  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>New Rule</span>
      </div>
      <Create className="test" sx={{ width: 480 }}>
        <SimpleForm onSubmit={postSave}>
          <AutocompleteInput source="storeId" choices={storesArray} label="store name" />
          <AutocompleteInput source="elementType" choices={elements} optionText="result" optionValue="result" label="element type" />
          <AutocompleteInput source="fieldType" choices={fields} optionText="result" optionValue="result" label="field type" />
          <TextInput source="xpath" multiline label="xpath" />
        </SimpleForm>
      </Create>
    </Wrapper>
  );
};

export default ScrapingCreate;
