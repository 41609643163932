import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  DateField,
  Button,
  useRecordContext,
  useGetList,
  useRefresh,
  SingleFieldList,
  useRedirect,
  BooleanField,
  SearchInput,
  Filter,
} from "react-admin";
import MyUrlField from "../elements/MyUrlField";
import MyArrayField from "../elements/MyArrayField";
import StoreCreate from "./StoreCreate";
import StoreEdit from "./StoreEdit";
import StoreOrder from "./StoreOrder";
import DeleteStore from "./DeleteStore";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import Wrapper from "../styles/store/StoreList";
// import arrow from "../../assets/arrow.png";
import ArrayTextField from "../elements/ArrayTextField";
import RemoveIcon from "@mui/icons-material/Remove";

export const StoreList = (props) => {
  const { data } = useGetList("categories");
  const choice = data;

  const refresh = useRefresh();
  const redirect = useRedirect();

  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [order, setOrder] = React.useState(false);
  const [deleteStore, setDeleteStore] = React.useState(false);
  const [recordForEdit, setRecordForEdit] = React.useState(3);

  // const CategoriesField = ({ source }) => {
  //   const record = useRecordContext();

  //   <ul>
  //     {record.categories.map((item) => (
  //       <li key={item.name}>{item.name}</li>
  //     ))}
  //   </ul>;
  // };

  const postRowStyle = (record, index) => ({
    // eslint-disable-next-line
    outline: record.id == recordForEdit.id ? "1px solid #ff6d44" : "",
  });

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => {
      redirect("/stores");
      refresh();
    }, 1800);
  };
  const editModal = () => {
    setEdit(false);
    setTimeout(() => {
      redirect("/stores");
      refresh();
    }, 1800);
  };

  const orderModal = () => {
    setOrder(false);

    setTimeout(() => {
      redirect("/stores");
      refresh();
    }, 1400);
    setTimeout(() => {
      setRecordForEdit(0);
    }, 1800);
  };

  const ListActions = React.memo(() => [
    <Button
      style={{ margin: 0, marginBottom: 10, marginRight: 10 }}
      color="primary"
      aria-label="create"
      onClick={() => setOpen(true)}
    >
      Add New
      <AddIcon
        style={{
          marginLeft: "4px",
          marginTop: "2px",
          fontSize: "13px !important",
        }}
      />
    </Button>,
  ]);

  const DeleteStoreButton = ({ source }) => {
    const record = useRecordContext();
    const openInPopup = (record) => {
      setRecordForEdit(record);
      setDeleteStore(true);
    };
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{ marginTop: "0px" }}
        >
          <CloseIcon />
        </Button>
      </>
    );
  };

  const EditStoreButton = ({ source }) => {
    const record = useRecordContext();

    const openInPopup = (record) => {
      setRecordForEdit(record);
      setEdit(true);
    };
    return (
      <div>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => {
            openInPopup(record);
          }}
        >
          <EditOutlinedIcon style={{ marginRight: "4px" }} />
        </Button>
      </div>
    );
  };

 

  return (
    <Wrapper>
        <List
          actions={<ListActions />}
          filters={
            <Filter>
              <SearchInput
                style={{
                  position: "absolute",
                  left: 20,
                  bottom: 1,
                  backgroundColor: "#FFF",
                  borderRadius: 10,
                }}
                variant="outlined"
                source="search"
                size="small"
                alwaysOn
              />
            </Filter>
          }
          // {...props}
          perPage={14}
        >
          <Datagrid rowStyle={postRowStyle} >
            <TextField sortable={true} source="id" label="id" defaultValue="" />
            <ImageField
              sortable={false}
              source="logoUrl"
              sx={{ maxWidth: 70 }}
              title="title"
              label="Logo"
            />
            <TextField
              source="name"
              sortable={true}
              className="shorter-text-small"
            />
            <MyUrlField source="url" target="_blank" />
            <MyArrayField source="categories" label="Category" sortable={false}>
              <SingleFieldList>
                <ArrayTextField source="name" />
              </SingleFieldList>
            </MyArrayField>
            {/* <CategoriesField/> */}
            <TextField
              sortable={true}
              source="allVisits"
              label="Total Visits"
              defaultValue=""
            />
            <TextField
              sortable={true}
              source="uniqueMonthlyVisits"
              label="Monthly Unique"
              defaultValue=""
            />
            <TextField
              sortable={true}
              source="monthlyVisits"
              label="Monthly Visits"
              defaultValue=""
            />
            <BooleanField
              source="isVerified"
              label="Verified"
              sortable={true}
              FalseIcon={RemoveIcon}
            />
            <BooleanField
              source="isDeleted"
              label="Deleted"
              sortable={true}
              FalseIcon={RemoveIcon}
            />

            <DateField
              source="dateAdded"
              sortable={true}
              options={{
                weekday: "short",
                year: "2-digit",
                month: "short",
                day: "numeric",
              }}
              label="Date"
            />

            <EditStoreButton label="Actions" />
            <DeleteStoreButton />
          </Datagrid>
        </List>

        <Dialog open={open} onClose={() => setOpen(false)}>
          <StoreCreate items={choice} onAddStore={closeModal} />
        </Dialog>

        <Dialog open={edit} onClose={() => setEdit(false)}>
          <StoreEdit
            items={choice}
            recordForEdit={recordForEdit}
            onEditStore={editModal}
          />
        </Dialog>
        <Dialog open={order} onClose={() => setOrder(false)}>
          <StoreOrder recordForEdit={recordForEdit} onEditOrder={orderModal} />
        </Dialog>
        <Dialog open={deleteStore} onClose={() => setDeleteStore(false)}>
          <DeleteStore
            recordForEdit={recordForEdit}
            onCancelDelete={() => setDeleteStore(false)}
            onDeleteStore={closeModal}
          />
        </Dialog>
       
    </Wrapper>
  );
};
