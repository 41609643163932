import React from "react";
import {
  Create,
  SimpleForm,
  DateInput,
  TextInput,
  BooleanInput,
  ImageInput,
  ImageField,
  AutocompleteInput,
  useCreate,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import Wrapper from "../styles/offers/OffersCreate"
import modal from "../../assets/modal.png";
import { Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';


const OffersCreate = (props) => {
  const arr = props.stores;
  const storesFiltered = arr.filter((el) => el.isDeleted !== true && el.isVerified === true);
  // const [stores, setStores] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(stores_url, {
  //         method: 'GET',
  //         headers: {
  //           "Content-Type": "application/json",
  //           "authorization": "Bearer " + UserService.getToken()
  //         },
  //       });
  //       const json = await response?.json();
  //       let results = json;
  //       setStores(results);
  //     } catch (error) {
  //       console.log("error", error)
  //     }
  //   }
  //   fetchData();
  // }, []);

  // const storesArray = stores

  const [create] = useCreate();
  const postSave = (data) => {
    console.log(data);
    create("offers", { data });
    // notify(`Changes saved`);
    props.onAddOffer();

  };

  const TooltipImageInfo = () => {
    return (
      <div>
        <Typography sx={{fontSize:14,fontWeight:600}}>Background Image</Typography>
        <Tooltip title="”Preferred ratio 16:9 - 1366x768, 1280×720, 854×480”" placement="right-end">
          <InfoIcon style={{height:24,width:24}} color="info" />
        </Tooltip>
      </div>
    )
  }

  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>New Offer</span>
      </div>
      <Create className="test" sx={{ width: 550 }}  >
        <SimpleForm onSubmit={postSave}>
          <ImageInput
            source="logoUrl"
            label="Logo"
            accept="image/*"
            placeholder={<AddIcon  />}
          >
            <ImageField source="logoBase64" title="title" />
          </ImageInput>
          <AutocompleteInput source="storeId" choices={storesFiltered} />
          <ImageInput
            source="backgroundImageUrl"
            label={<TooltipImageInfo />}
            accept="image/*"
            placeholder={<p>Image</p>}
          >
            <ImageField source="logoBase64" title="title" />
          </ImageInput>
          <TextInput source="title" label="Title" />
          <TextInput source="description" label="Description" multiline />
          <BooleanInput source="isActive" label="Active" />
          <TextInput source="promoUrl" label="Promo URL" />
          <DateInput source="startDate" label="Offer Start *" />
          <DateInput source="endDate" label="Offer End *" />
        </SimpleForm>
      </Create>
    </Wrapper>
  );
};

export default OffersCreate;
