import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  AutocompleteArrayInput,
  ImageInput,
  ImageField,
  useCreate,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import Wrapper from "../styles/store/StoreCreate";
import modal from "../../assets/modal.png";

const StoreCreate = (props) => {
  console.log(props);

  const choices = props.items;

  const [create] = useCreate();
  const postSave = async (data) => {
    //  const newStore = data;

    const resp = await create(
      "stores",
      { data },
      {
        // onSuccess: (data) => refresh(data)
      }
    );

    console.log(resp)

    props.onAddStore();
  };

  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>New Store</span>
      </div>
      <Create className="test" sx={{ width: 480 }} redirect="list">
        <SimpleForm onSubmit={postSave}>
          <ImageInput
            source="logoUrl"
            label="Logo"
            accept="image/*"
            placeholder={<AddIcon />}
          >
            <ImageField source="logoBase64" title="title" />
          </ImageInput>
          <TextInput source="name" label="Name" />
          <TextInput source="url" label="URL Address" />
          <BooleanInput source="isPartner" label="NewPay Partner" />
          <AutocompleteArrayInput
            source="categories"
            choices={choices}
            optionValue="name"
          />
        </SimpleForm>
      </Create>
    </Wrapper>
  );
};

export default StoreCreate;
