import { styled } from '@mui/system';

const Wrapper = styled('div')({
  color: 'darkslategray',
  backgroundColor: 'aliceblue',
  padding: 8,
  borderRadius: 4,
  borderCollapse: "separate",
  borderSpacing: 0,
  ".MuiTableRow-root": {
    borderRadius: 20,
    boxShadow: "0px 5px 10px 2px #dee5e7",
    // backgroundColor: "#fff"

  },
  ".MuiTableRow-root td:nth-child(2)": {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    paddingLeft: "0px !important"
  },
  ".MuiTableRow-root td:last-child": {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    marginRight: 20,
    paddingLeft: "0px !important"
  },
  ".column-startDate, .column-endDate": {
    textAlign: "left !important"
  },
  ".css-rqglhn-MuiTable-root": {
    borderCollapse: "separate",
    borderSpacing: "0 10px"
  },
  ".MuiPaper-root": {
    backgroundColor: "transparent",
    boxShadow: "none"
  },
  ".MuiTableRow-root td:nth-child(5)": {
    textAlign: "center",
  },
  ".MuiTableRow-root td:nth-child(4)": {
    textAlign: "left",
    minWidth: 65
  },
  ".MuiTableRow-root td:nth-child(6)": {
    textAlign: "left",
  }, ".MuiTableRow-root td:nth-child(7)": {
    textAlign: "left",
  },
  ".MuiTableCell-head": {
    backgroundColor: "transparent !important",
    boxShadow: "none !important"
  },
  ".MuiTableRow-head": {
    backgroundColor: "transparent !important",
    boxShadow: "none !important"
  },
  ".MuiTableRow-hover:hover": {
    backgroundColor: "transparent !important",
    outline: "1px solid #ff6d44",
    color: "#BF2279"
  },
  ".MuiTableRow-hover:hover td": {
    color: "#BF2279",
    backgroundColor: "#fff !important",

  },
  ".MuiTableRow-root .MuiBox-root ": {
    maxHeight: 35,

  },
  ".MuiTableRow-root .MuiBox-root img": {
    maxHeight: 36,
    maxWidth: 62
  },
  ".column-undefined": {
    maxWidth: 30,
    padding: 0
  },
  ".column-undefined button": {
    justifyContent: "flex-start",
    marginLeft: "3px",
  },
  ".column-undefined a span": {
    borderRight: "1px solid #BF2279"
  },
  ".column-undefined a span svg": {
    marginRight: "3px"
  },
  ".column-id": {
    textAlign: "center"
  },
  ".MuiTableCell-body": {
    // padding:'4px 2px',
    textAlign: "center"

  },
  ".MuiBox-root img": {
    width: 'auto',
    maxWidth: 62
  },
  ".RaList-main": {
    minHeight: "82vh"
  },
  ".css-1or4sgo-MuiButtonBase-root-MuiButton-root-RaButton-root:hover": {
    backgroundColor: "#fff"
  },
  ".css-e784if-MuiTypography-root": {
    // fontSize: "26px",
    // textAlign: "center",
    marginTop: "90px"
  },
  ".column-promoUrl, .column-description": {
    textAlign: "left"
  },
  ".column-isActive": {
    textAlign: "center"
  },
  ".css-1or4sgo-MuiButtonBase-root-MuiButton-root-RaButton-root": {
    marginTop: 7
  },
  ".MuiFilledInput-root:after": {
    borderBottom: "none"
  },

  ".column-store.logoUrl .MuiBox-root": {
    maxWidth: "36px !important"
  },
  ".column-backgroundImageUrl .MuiBox-root": {
    justifyContent: "flex-start",
    position: "relative"
  },
  ".column-logoUrl .MuiBox-root": {
    justifyContent: "flex-start"
  },
  ".MuiTableCell-root": {
    padding: "6px 6px !important"
  },
  ".MuiTableRow-root td:nth-child(13)": {
    maxWidth: "5px !important",
  },
  ".MuiPaper-root .MuiButton-root": {
    padding: "4px 0 !important"
  },




});


export default Wrapper;
