import React from 'react';
import {
  NumberInput,
  useUpdate,
  Form,  
  SaveButton,
} from "react-admin";
import Wrapper from "../styles/popups/Order"
import modal from "../../assets/modal.png";

const NewStoreOrder = (props) => {
  const { recordForEdit } = props
 
  const [update] = useUpdate();
   
  const orderEdit = (data) => {
    const selectedPosition = data.position;
    update("ordering/addStore", { id: recordForEdit.id, data: {orderTypeId:3, position:selectedPosition} });
    props.onEditOrder()
  } 

  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Change position</span>
      </div>
       
      <Form className='modal-content' onSubmit={orderEdit} sx={{ width: 540 }} > 
          <NumberInput
            source="position"
            label="Order Id" 
            defaultValue={recordForEdit.position}          
          />     
                    <SaveButton
           
     
    
        >Save
          {/* <img src={arrow} alt="arrow" style={{maxWidth: "15px"}}/>             */}
         
        </SaveButton>       
   </Form>
      
    </Wrapper>
  );
};

export default NewStoreOrder;