import React, { useEffect } from "react";
import {
  Edit,
  SimpleForm,
  DateInput,
  TextInput,
  ImageInput,
  BooleanInput,
  useUpdate,
  ImageField,
  AutocompleteArrayInput,
  FormDataConsumer,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import Wrapper from "../styles/store/StoreEdit";
import modal from "../../assets/modal.png";

const StoreEdit = (props) => {
  const { recordForEdit } = props;
  const [storeId, setStoreId] = React.useState(1);
  console.log(recordForEdit);

  useEffect(() => {
    setStoreId(recordForEdit.id);
  }, [recordForEdit]);

  const [update] = useUpdate();

  const storeEdit = (data) => {
    const editedCategories = data.categories.map((a) => a.name);
    console.log(data);

    update("stores", {
      id: recordForEdit.id,
      data: { ...data, categories: editedCategories },
      previousData: recordForEdit,
    });


    props.onEditStore();

  };
  const choices = props.items;


  const ParseResponse = (value) => {
    console.log(value);
    if (value != null) {
      const CategoryName = value.map((v) => ({ name: v }));
      return CategoryName;
    }
  };

  return (
    // <Edit>
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Edit Store </span>
      </div>
      <Edit
        id={storeId}
        className="test"
        sx={{ width: 550 }}
        mutationMode="optimistic"

      >
        <SimpleForm onSubmit={storeEdit} className="old-image-form">


          <FormDataConsumer className="old-image">
            {({ formData, dispatch, ...rest }) => {
              if (!formData.logoUrls) {
                return (
                  <div>

                    <ImageField source="logoUrl" {...rest} />

                  </div>
                );
              }
            }}
          </FormDataConsumer>
          <ImageInput
            source="logoUrls"
            label="Logo"
            accept="image/*"
            placeholder={<AddIcon />}
          >
            <ImageField source="logoBase64" title="title" />
          </ImageInput>

          {/* <TextInput disabled source="id" /> */}
          <TextInput source="name" label="Name" />
          <TextInput source="url" label="URL Address" />
          <BooleanInput source="isPartner" label="NewPay Partner" />

          <AutocompleteArrayInput
            source="categories"
            format={(value) => value && value.map((v) => v.name)}
            // format={value => value && value.map(v => ({ id: v, name: v }))}

            // format={ParseFormat}

            // parse={value => value && value.map(v => v.id)}
            parse={ParseResponse}
            optionValue="name"
            choices={choices}
          />
          <DateInput source="dateAdded" className="store-date-field" />
          <DateInput source="dateEdited" className="store-date-field" />

          <BooleanInput source="isVerified" label="Verified" />
          <BooleanInput source="isDeleted" />
        </SimpleForm>
      </Edit>
    </Wrapper>
  );
};

export default StoreEdit;
