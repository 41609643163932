import * as React from "react";

import { useLocation } from "react-router-dom";
import { Sidebar, useSidebarState } from "react-admin";
import StoreSubmenu from "./StoreSubmenu";
import OffersSubmenu from "./OffersSubmenu";
import CategoriesSubmenu from "./CategoriesSubmenu";
import DeliverySubmenu from "./DeliverySubmenu";
import logo from "../../assets/logoProfile.png";
import UserService from "../../UserService";

const MySidebar = (props) => {
  const location = useLocation();
  const [open] = useSidebarState();

const sidebarClassName = open  ?"sidebar-open" : "sidebar-closed"

  return (
    <Sidebar
      className={sidebarClassName}
      sx={{
        backgroundColor: "#BF2279",
        "& .RaAppBar-title": {
          display: "none",
        },
        "& .RaAppBar-menuButton": {
          paddingLeft: 15,
        },
      }}
      {...props}
    >
      <div className="user-profile">
        {open && (
          <>
            <img src={logo} alt="logo" />
            <div className="user-name">
              <span className="user-first-name">John Doe</span>
              <span className="user-last-name">
                {" "}
                {UserService.getUsername()}
              </span>
            </div>
          </>
        )}
      </div>
      {location.pathname === "/" ? <StoreSubmenu /> : null}
      {location.pathname.includes("stores") ? <StoreSubmenu /> : null}
      {location.pathname.includes("offers") ? <OffersSubmenu /> : null}
      {location.pathname.includes("categories") ? <CategoriesSubmenu /> : null}
      {location.pathname.includes("delivery") ? <DeliverySubmenu /> : null}
      {location.pathname.includes("scraping") ? <StoreSubmenu /> : null}
      {location.pathname.includes("wishlist") ? <StoreSubmenu /> : null}
      {/* {location.pathname.includes("partners") ? <StoreSubmenu /> : null} */}
    </Sidebar>
  );
};

export default MySidebar;
