import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ImageInput,
  ImageField,
  useCreate
} from "react-admin";
import Wrapper from "../styles/categories/CategoryCreate"
import modal from "../../assets/modal.png";

const CategoryCreate = props => {
  const arr = props.items;
  const storesFiltered = arr.filter((el) => el.isDeleted !== true && el.isVerified === true);

  const [create] = useCreate();
  const categorySave = (data) => {
    console.log(data);
    create("categories", { data });
    // notify(`Changes saved`);
    props.onAddCategory();
  };

  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>New Category</span>
      </div>
      <Create className="test" sx={{ width: 480 }}>
        <SimpleForm onSubmit={categorySave}>
          {/* <TextInput disabled source="id" /> */}
          <TextInput source="name" label="Title" />
          <ImageInput
            source="iconUrl"
            label="Category Image"
            accept="image/*"
            placeholder={<div>Image</div>}
          >
            <ImageField source="logoBase64" title="title" />
          </ImageInput>
          < AutocompleteArrayInput source="storeIds" choices={storesFiltered}
            optionValue="id" optionText="name" label="Store Multi-Select"
          />

        </SimpleForm>
      </Create>
    </Wrapper>
  );
};

export default CategoryCreate;
