import * as React from 'react'; 
import logo from '../assets/logo2.png'
import styled from "styled-components";
import { Link } from 'react-router-dom';

 

const Logo = () =>{
 return <Wrapper className="logo-box">

    <div className="logo-container">
      <Link to="/">  
      <img src={logo} alt="logo" />
      </Link>
    </div>
 </Wrapper>
}
 

const Wrapper = styled.div`
  width:100px;
  position:absolute;
  left:10px;
  top:13px;
  img{
    width:100%;
  }
`

export default Logo;