import React, { useState, useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  DateInput,
  TextInput,
  ImageInput,
  BooleanInput,
  useUpdate,
  ImageField,
  FormDataConsumer,
  AutocompleteInput,
  Labeled
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import Wrapper from "../styles/offers/OfferEdit"
import modal from "../../assets/modal.png";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { stores_url } from "../../constants";
import UserService from "../../UserService";

const OfferEdit = (props) => {
  const { recordForEdit } = props
  const [storeId, setStoreId] = React.useState(1)

  const [stores, setStores] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(stores_url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "authorization": "Bearer " + UserService.getToken()
          },
        });
        const json = await response?.json();
        let results = json;
        setStores(results);
      } catch (error) {
        console.log("error", error)
      }
    }
    fetchData();
  }, []);

  const storesArray = stores


  useEffect(() => {
    setStoreId(recordForEdit.id)

  }, [recordForEdit]);

  const [update] = useUpdate();


  const offerEdit = (data) => {
    const selectedStore = data.store.id;
    console.log(data)
    update("offers", { id: recordForEdit.id, data: { ...data, storeId: selectedStore }, previousData: recordForEdit });
    props.onEditOffer()
    // refresh();

  }

  return (
    // <Edit>
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Edit Offer </span>
      </div>
      <Edit id={storeId} className="test" sx={{ width: 550 }} mutationMode="optimistic" >
        <SimpleForm onSubmit={offerEdit}>
          <ImageInput
            source="logoUrls"
            label="Logo Image"
            accept="image/*"
            placeholder={<AddIcon />}
          >
            <ImageField source="logoBase64" title="title" />
          </ImageInput>
          {/* <Labeled label="logo" className='logo-field-label'> 
                  <ImageField
            source="logoUrl"
            sx={{ maxWidth: 70, borderRadius: "10 10 0 0" }}
            title="title"
            label="Logo"
            className='logo-field'
          />
          </Labeled> */}
          <AutocompleteInput source="store.id" choices={storesArray} label="Store name" />
          <Labeled label="Background Image" className='logo-field-label'>

            <FormDataConsumer className="old-image">
              {({ formData, dispatch, ...rest }) => {
                if (!formData.backgroundImageUrls) {
                  return (
                    <div>

                      <ImageField source="backgroundImageUrl" {...rest} label="Background Image"
                        className='background-field' />

                    </div>
                  );
                }
              }}
            </FormDataConsumer>

          </Labeled>

          <ImageInput
            source="backgroundImageUrls"
            label=" "
            accept="image/*"
            placeholder={<p> <EditOutlinedIcon style={{ marginBottom: "-4px", fontSize: "12px !important", width: "15px" }} />Change</p>}

          >
            <ImageField source="logoBase64" title="title" />
          </ImageInput>
          {/* <TextInput source="storeId" label="storeId" /> */}
          <TextInput source="title" label="Title" />

          <TextInput source="description" label="Description" multiline />
          <TextInput source="promoUrl" label="Promo URL" />
          <BooleanInput source="isActive" label="Active" />
          <DateInput source="startDate" label="Offer Start" />
          <DateInput source="endDate" label="Offer End" />
        </SimpleForm>
      </Edit>
    </Wrapper>
  );
};

export default OfferEdit;
