import { styled } from "@mui/system";


const Wrapper = styled("div")({
    color: "darkslategray",
    padding: 8,
    borderCollapse: "separate",
    borderSpacing: 0,
    ".MuiTableRow-root": {
      borderRadius: 20,
      boxShadow: "0px 5px 10px 2px #dee5e7",
      // backgroundColor: "#fff",
    },
    ".MuiTableRow-root td:nth-child(2)": {
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    ".MuiTableRow-root td:last-child": {
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: 20,
      maxWidth: 10,
      paddingLeft: '0 !important',
      paddingRight: '14px !important',
      textAlign: "center"

    },
    ".MuiTableRow-root td:nth-child(6)": {
      textAlign: "center",
    },
    ".MuiTableRow-root td:nth-child(6) span": {
      paddingRight: 10,
      display: "inline-block",
      minWidth: 20,
      textAlign: "right"
    },
    // ".MuiTableRow-root td:nth-child(7)": {
    //   textAlign: "center",
    // },
    ".MuiTableRow-root td:nth-child(8)": {
      textAlign: "center",
    },
    ".MuiTableRow-root td:nth-child(8) span": {
      paddingRight: 16,
      display: "inline-block",
      minWidth: 20,
      textAlign: "right"
    },
    ".css-rqglhn-MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0 10px",
    },
    ".MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    ".MuiTableCell-head": {
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
    ".MuiTableRow-head": {
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
    ".MuiTableRow-hover:hover": {
      backgroundColor: "transparent !important",
      outline: "1px solid #ff6d44",
      color: "#BF2279",
    },
    ".MuiTableRow-hover:hover td": {
      color: "#BF2279",
    backgroundColor: "#fff !important",

    },
    ".MuiTableRow-root .MuiBox-root ": {
      maxHeight: 35,
    },
    ".MuiTableRow-root .MuiBox-root img": {
      maxHeight: 26,
    },
    ".column-undefined": {
      maxWidth: 20,
      padding: 0,
    },
    ".column-undefined button": {
      justifyContent: "flex-start",
      marginLeft: "3px",
      minWidth: 15
    },
    ".column-undefined a span": {
      borderRight: "1px solid #BF2279",
    },
    ".column-undefined a span svg": {
      marginRight: "3px",
    },
    ".MuiBox-root img": {
      width: "auto",
      maxWidth: 62
    },
    ".MuiButton-root:focus::after ": {
      opacity: 0,
    },
    ".MuiTableCell-root.column-undefined button:hover": {
      backgroundColor: "transparent !important",
    },
    ".MuiTableCell-root.column-undefined button svg": {
      color: "#BF2279",
    },
    ".column-logoUrl":{
      minWidth: 42,
      width: 72
    },
    ".css-lwyovy-MuiToolbar-root-RaTopToolbar-root":{
      display:'none'
    },
    ".column-backgroundImageUrl .MuiBox-root": {
      justifyContent: "flex-start !important"
    },
    ".column-uniqueMonthlyVisits": {
      textAlign: 'center'
    },
    ".MuiTableRow-root td:nth-child(10)": {
     width: 10
    },
    "th .MuiTableRow-root td:nth-child(10)": {
      paddingLeft: "18px !important"
     },
     ".MuiTableCell-head.column-logoUrl span": {
      paddingLeft: "16px"
     },
     ".column-isVerified, .column-isDeleted": {
      textAlign: "center"
     }
    //  ".list-page":{
    //   position: "relative"
    //  },
    //  ".RaList-main": {
    //   position: "relative",
    //   // left: 0,
    //   // right: 0,
    //   //  top: 0
    //  },
    //  ".css-1d00q76-MuiToolbar-root-RaListToolbar-root": {
    //   position: "absolute",
    //   left: 0,
    //   right: 0,
    //    top: 0
    //  },
    // ".RaDatagrid-tableWrapper": {
    //   marginTop: "54px !important"
    // }
 
  });

  export default Wrapper;