import React from 'react';
import {
  useDelete,
  Button,
} from "react-admin";
import Wrapper from "../styles/popups/Delete"
import modal from "../../assets/modal.png";

const MostSearchedDelete = (props) => {
  const { recordForEdit } = props

  const [deleteOne] = useDelete();

  const storeDelete = (data) => {
    deleteOne("ordering/id/2", { id: recordForEdit.id, data: { id: recordForEdit.id } });
    props.onDeleteStore()
  }

  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Delete</span>
      </div>
      {/* <Form id={storeId} className="test" sx={{ width: 550 }} mutationMode="optimistic" > */}
      <div className='modal-content'>

        <div className='modal-text'>Are you sure you delete {recordForEdit.name}?</div>
        <Button
          color="primary"
          onClick={storeDelete}
          style={{ marginTop: "0px" }}
        >Delete
          {/* <img src={arrow} alt="arrow" style={{maxWidth: "15px"}}/>             */}

        </Button>
      </div>
      {/* </Form> */}
    </Wrapper>
  );
};

export default MostSearchedDelete;