import Keycloak from 'keycloak-js'

 

const _kc = new Keycloak({
    url: "https://openid.finoid.eu/auth",    
    realm: "newpay",
    clientId: "target-client",
    publicClient :true
  });

const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    // onLoad: 'login-required',
    onLoad: 'check-sso',

    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
    publicClient: 'true',
    checkLoginIframe: false
  })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        console.log("user is not authenticated..!");
        doLogin();

      }
      
      })
      .catch(console.error);
};
const doLogin = _kc.login;

const doLogout = _kc.logout;
const isTokenExpired = _kc.isTokenExpired;

const getToken = () => _kc.token;
const newRefreshToken =()=> _kc.refreshToken;

console.log(_kc);

const isLoggedIn = () => !!_kc.token;
 

const updateToken = (successCallback) =>
  _kc.updateToken(30)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.family_name;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  newRefreshToken,
  isTokenExpired
};

export default UserService;