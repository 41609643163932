import { styled } from "@mui/system";

const Wrapper = styled("div")({
  ".MuiTextField-root": {
    width: 560,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ".MuiTextField-root label": {
    position: "relative",
    transform: "none",
    fontSize: "14px !important",
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
  },
  ".RaLabeled-label span, .MuiFormControlLabel-label": {
    fontSize: "14px !important",
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
  },
  ".ra-input-logoUrl": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "spaceBetween",
    alignItems: "center",
  },
  ".ra-input-logoUrl .RaFileInput-dropZone": {
    width: 26,
    border: "1px solid #c4c4c4",
    borderRadius: 5,
    marginLeft: 158,
    background: "transparent",
  },
  ".MuiFormControlLabel-root": {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    width: 285,
    marginLeft: 0,
  },
  ".MuiFilledInput-root:before": {
    borderBottom: "none",
  },
  ".MuiFilledInput-root input": {
    background: "#fff",
    border: "1px solid #c4c4c4",
    borderRadius: 41,
    padding: 10,
  },
  ".MuiFilledInput-root:hover, .MuiFilledInput-root:focus": {
    borderRadius: 41,
  },
  ".MuiFilledInput-root": {
    background: "#fff",
  },
  ".css-i6tejy-MuiInputBase-root-MuiFilledInput-root": {
    marginLeft: 95,
    width: 282,
    marginRight: -35,
  },
  ".css-1r5okjt-RaCreate-root": {
    width: 550,
  },
  ".MuiInputLabel-root": {
    minWidth: 84,
  },
  ".RaLabeled-label": {
    width: 75,
  },
  ".css-1xuwtk8-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked": {
    color: "#fff",
    // backgroundColor:"#BF2279"
  },
  ".MuiSwitch-switchBase input[type=checkbox]": {
    color: "#BF2279",
    // backgroundColor:"#BF2279"
  },
  "input[type=checkbox]:checked": {
    color: "#BF2279",
    // backgroundColor:"#BF2279"
  },
  "input[type=checkbox]:checked:before": {
    color: "#BF2279",
  },
  "input[type=checkbox i] ": {
    backgroundColor: "#BF2279",
  },
  ".RaToolbar-defaultToolbar .MuiButton-root": {
    backgroundColor: "#BF2279",
    borderRadius: 20,
    color: "#fff",
    textTransform: "capitalize",
  },
  ".RaToolbar-defaultToolbar ": {
    justifyContent: "flex-end !important",
    // backgroundColor: "#fff"
  },
  ".modal-top": {
    width: "550px !important",
    height: "auto",
    position: "relative",
    marginBottom: "-4px"
  },
  ".modal-top img": {
    width: "100%",
    height: "auto",
  },
  ".modal-top span": {
    position: "absolute",
    top: 15,
    left: 38,
    color: "#fff",
    fontWeight: 500,
    fontSize: 16,
  },
  ".MuiInputBase-root:before": {
    borderBottom: "none",
  },
  ".MuiFilledInput-root input::after": {
    borderBottom: "1px solid #c4c4c4",
  },
  ".css-i6tejy-MuiInputBase-root-MuiFilledInput-root:after": {
    borderBottom: "none",
  },
  ".css-1xuwtk8-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
  {
    backgroundColor: "#BF2279",
    opacity: 1,
  },
  ".MuiSwitch-thumb": {
    width: 12,
    height: 12,
    marginTop: 4,
    marginLeft: 4,
  },
  ".RaFileInput-preview": {
    marginLeft: 20,
  },
  ".RaFileInput-preview img": {
    width: "100%",
  },
  ".MuiInputBase-root:hover": {
    borderBottom: "none"
  },
  ".MuiInputBase-root.MuiFilledInput-root:hover:not(.Mui-disabled):before": {
    borderBottom: "none"
  },
  ".ra-input-backgroundImageUrl": {
    display: "flex",
    flexDirection: "row",
    marginTop: 14
  },
  ".ra-input-backgroundImageUrl .RaLabeled-label": {
    width: 232
  },
  ".ra-input-backgroundImageUrl .RaFileInput-dropZone": {
    width: 156,
    backgroundColor: "#f1f1f1",
    borderRadius: 5
  },
  ".ra-input-backgroundImageUrl .MuiFormHelperText-root": {
    display: "none"
  },
  ".RaAutocompleteInput-textField .MuiFilledInput-root": {
    width: 315,
    paddingTop: "0 !important",
    marginRight: 23
  },
  ".RaAutocompleteInput-textField .MuiFilledInput-root input": {
    padding: "10px !important"
  },
  ".RaAutocompleteInput-textField p": {
    display: "none"
  },
  ".RaAutocompleteInput-textField .MuiFilledInput-root:after": {
    borderBottom: "none !important"
  },
  ".ra-input-title p, .ra-input-description p, .ra-input-isActive p, .ra-input-promoUrl p": {
    display: "none"
  },
  ".ra-input-title .MuiFilledInput-root, .ra-input-description .MuiFilledInput-root, .ra-input-promoUrl .MuiFilledInput-root": {
    marginRight: 46
  },
  ".ra-input-title .MuiFilledInput-root input, .ra-input-description .MuiFilledInput-root input, .ra-input-promoUrl .MuiFilledInput-root input": {
    width: 262
  },

  "textarea": {
    marginRight: "-10px !important",
    width: "260px !important",

  },
  ".ra-input-description .MuiFilledInput-root": {
    paddingTop: "0 !important"

  },
  ".ra-input-description .MuiFilledInput-root:hover": {
    background: "#fff"
  },
  ".ra-input-description .MuiFilledInput-root:after": {
    borderBottom: "none",
  },
  ".MuiDialog-container>.MuiPaper-root": {
    overflowY: "none !important"
  },
  ".create-page": {
    height: 485,
    overflowY: "auto",
    width: 550
  }


});

export default Wrapper;