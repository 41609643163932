import { fetchUtils, HttpError, localStorageStore } from "react-admin";
import { stringify } from "query-string";
import UserService from "./UserService";
import { BASE_URL } from "./constants";

const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const userToken = UserService.getToken();
  options.headers.set("authorization", "Bearer " + userToken);
  if (!userToken) {
    UserService.doLogout();
  }

  return fetchUtils.fetchJson(url, options).catch(onError);
};

function onError(error) {
  if (error.status === 401) {
    UserService.doLogout();
  } else {
    localStorageStore().setItem("error.modal.open", true);
    localStorageStore().setItem("error.modal.content", error.message || "generic error");
  }
}

const apiUrl = BASE_URL;
const httpClient = fetchJson;

const dataProvider = {
  getList: async (resource, params) => {
    const search = params.filter?.search; // added as a query
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    let cleanField = field.includes(".") ? field.split(".").pop() : field;


    if (field === "product.name") {
      cleanField = "productName";
    }

    if (field === "elementType") cleanField = "element_type"; // modification for scrapping rules
    if (field === "fieldType") cleanField = "field_type"; // modification for scrapping rules

    const query = {
      sort: [cleanField, order].toString(),
      range: [(page - 1) * perPage, page * perPage - 1].toString(),
      filter: params.filter,
      search: search,
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    // try { // test errors
    //   const resp = await httpClient("https://newpay.infn.dev/api/test/ex?status=400");
    //   console.log(resp);
    // } catch (error) {
    //   onError(error);
    // }
    return httpClient(url)
      .then((resp) => {
        if (!resp) {
          return new HttpError("error fetching", 400, {});
        }
        return {
          data: resp.json,
          total: parseInt(
            resp.headers.get("content-range").slice(10).split("/").pop(),
            10
          ),
          status: resp.json.status,
        };
      })
      .catch((error) => {
        const status = error.status;
        if (status === 401) {
          UserService.doLogout();
        }
        onError(error);
        console.log(error);
      });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url)
      .then((resp) => {
        if (!resp) {
          return new HttpError("error fetching", 400, {});
        }
        return { data: resp.json };
      })
      .catch(onError);
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url)
      .then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }))
      .catch(onError);
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    })
      .then((resp) => {
        if (!resp) {
          return new HttpError("error fetching", 400, {});
        }
        return { data: { ...params.data, id: resp?.json.id } };
      })
      .catch(onError),

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then((resp) => {
      if (!resp) {
        return new HttpError("error fetching", 400, {});
      }
      return { data: resp.json };
    })
      .catch(onError),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    })
      .then((resp) => {
        if (!resp) {
          return new HttpError("error fetching", 400, {});
        }
        return { data: resp.json };
      })
      .catch(onError);
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    })
      .then((resp) => {
        if (!resp) {
          return new HttpError("error fetching", 400, {});
        }
        return { data: resp.json };
      })
      .catch(onError),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    })
      .then((resp) => {
        if (!resp) {
          return new HttpError("error fetching", 400, {});
        }
        return { data: resp.json };
      })
      .catch(onError);
  },
};

export default dataProvider;
