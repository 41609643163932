import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  UrlField,
  Edit,
  Button,
  SimpleForm,
  DateInput,
  TextInput,
  BooleanInput, 
  ArrayInput,
  SimpleFormIterator, 
  Show,
  useRefresh,
  SimpleShowLayout,
  useGetList,
  useRedirect,
  useRecordContext,
} from "react-admin";
import MostVisitedCreate from "./MostVisitedCreate";
import MostVisitedOrder from "./MostVisitedOrder";
import MostVisitedDelete from "./MostVisitedDelete";
 
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Wrapper from "../styles/most-visited/MostVisited"
import arrow from "../../assets/arrow.png"

 export const MostVisitedList = () => {
  const {data} = useGetList('stores', {      
    pagination: { page: 1, perPage: 170 }})
  const allStores = data;
 
  
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState(false);
  const [recordForEdit, setRecordForEdit] = React.useState(3);
  const [deleteStore, setDeleteStore] = React.useState(false);
  const refresh = useRefresh();
  const redirect = useRedirect();
   
  const closePopup = () => {
    setOpen(false);
    setTimeout(() => {       
      refresh();
    }, 1000);
   
  }

  const closeModal = () => {
    setDeleteStore(false);
    setTimeout(() => {       
      refresh();
    }, 1000);
   
  }

  const orderModal=()=> {
    setOrder(false);    
    setTimeout(() => {       
      redirect('/stores/ordered/1');
      refresh();
    }, 1400);
    setTimeout(() => {
      setRecordForEdit(0)}, 1800
    )
  }
  

  const CreateStoreButton = (props) => {
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => setOpen(true)}
        >
         Add New
          <AddIcon style={{marginLeft: "4px",
    marginTop: "2px",
    fontSize: "13px !important",
    }} />  
        </Button>
      </>
    );
  };

  const EditOrderButton = ({source}) => {
    const record = useRecordContext();
    const openInPopup = record => {
      setRecordForEdit(record)
      setOrder(true)
    }
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{marginTop: "0px"}}
        >
          <img src={arrow} alt="arrow" style={{maxWidth: "15px"}}/>            
         
        </Button>
      </>
    );
  };

  const DeleteStoreButton = ({source}) => {
    const record = useRecordContext();
    const openInPopup = record => {
      setRecordForEdit(record)
      setDeleteStore(true)
    }
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{marginTop: "0px"}}
        >
            <CloseIcon />         
         
        </Button>
      </>
    );
  };


  const postRowStyle = (record, index) => ({// eslint-disable-next-line
    outline: record.id == recordForEdit.id ? '1px solid #ff6d44' : ''
  }) 
  
  return (  
    <Wrapper>  
    <List actions={<CreateStoreButton  />} perPage={14}      empty={false} >
      <Datagrid rowStyle={postRowStyle}  sx={{ textDecoration: 'none',
     "& .MuiTableRow-root": {    
      borderRadius: "20px",
  } ,
  "& .RaAppBar-menuButton": {
      paddingLeft:15              
  }  }}>   
  <TextField source="position" label="Order Id" sortable={false}/> 
        <ImageField
          source="logoUrl"
          sx={{ maxWidth: 70,
          borderRadius: "10 10 0 0"}}
          title="title"
          label="Logo"
          sortable={false}
        />
        <TextField source="name" sortable={false} className="shorter-text-wishlist"/>
        <UrlField source="url" label="URL Address" sortable={false} className="shorter-text-wishlist"/>
   
        {/* <DateField
          source="dateAdded"
          options={{
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          }}
          label="Date"
        /> */}
       <EditOrderButton label="Actions"/>
       <DeleteStoreButton label=" "  />
         
      </Datagrid>
    </List>

    <Dialog open={open} onClose={() => setOpen(false)}>
          <MostVisitedCreate    stores={allStores}    onAddStore={closePopup} />
        </Dialog>

        <Dialog open={order} onClose={() => setOrder(false)}>
          <MostVisitedOrder recordForEdit={recordForEdit}  onEditOrder= {orderModal} />
        </Dialog>

        <Dialog open={deleteStore} onClose={() => setDeleteStore(false)}>
          <MostVisitedDelete  recordForEdit={recordForEdit}       onDeleteStore={closeModal} />
        </Dialog>
 
    </Wrapper>
  )
};

export const MostVisitedEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="name" />
        <TextInput source="url" />
        <TextInput source="logoUrl" />
        <BooleanInput source="isPartner" />
        <ArrayInput source="categories">
          <SimpleFormIterator>
            <TextInput source="id" />
            <TextInput source="name" />
            <TextInput source="iconUrl" defaultValue=""/>
            <TextInput source="position" defaultValue=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <DateInput source="dateAdded" />
        <DateInput source="dateEdited" />    
      </SimpleForm>
    </Edit>
  );
};


 

   

  export const MostVisitedShow = props => {
    return (
      <Show>
      <SimpleShowLayout>
          {/* <TextInput disabled source="id" /> */}
          <TextInput source="name" />
          <TextInput source="url" />
          <TextInput source="logoUrl" />
          <BooleanInput source="isPartner" />
          <ArrayInput source="categories">
            <SimpleFormIterator>
              <TextInput source="id" />
              <TextInput source="name" />
              <TextInput source="iconUrl" />
              <TextInput source="position" />
            </SimpleFormIterator>
          </ArrayInput>
          <DateInput source="dateAdded" />
          <DateInput source="dateEdited" />      
          </SimpleShowLayout>
    </Show>
    );
  };