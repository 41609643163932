import React, {  useEffect } from "react";

import {
   ImageField,
  Edit,
  SimpleForm,
  ImageInput,
  TextInput,
  useUpdate,
  AutocompleteArrayInput
} from "react-admin";
import Wrapper from "../styles/categories/CategoryCreate";
import modal from "../../assets/modal.png";
import AddIcon from "@mui/icons-material/Add";


export const CategoryEdit = (props) => {
  const { recordForEdit } = props;
  const arr = props.items;
  const storesFiltered = arr.filter((el)=> el.isDeleted !== true && el.isVerified === true );
  const [storeId, setStoreId] = React.useState(1);
  
  useEffect(() => {
    setStoreId(recordForEdit.id);
  }, [recordForEdit]);

  const [update] = useUpdate();

  const storeEdit = (data) => {
    console.log(data)
    const addedStores = data.stores.map((a) => a.id);
    update("categories", {
      id: recordForEdit.id,
      data: { ...data, storeIds:addedStores },
      previousData: recordForEdit,
    });

    props.onEditCategory();

  };
  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Edit Category </span>
      </div>
      <Edit
        id={storeId}
        className="test"
        sx={{ width: 550 }}
      >
        <SimpleForm onSubmit={storeEdit}>
          <TextInput source="name" />
          <ImageInput
            source="iconUrls"
            label="Category Image"
            accept="image/*"
            placeholder={<AddIcon />}
          >
            <ImageField source="logoBase64" title="title" />
          </ImageInput>
          < AutocompleteArrayInput source="stores" choices={storesFiltered}
            optionValue="id"
             optionText="name" 
            label="Store Multi-Select"
            parse={value =>value && value.map(v => ({ id: v }))}
          format={value => value && value.map(v => v.id)}

          />


        </SimpleForm>
      </Edit>
    </Wrapper>
  );
};