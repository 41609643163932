import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  Button,
  useRecordContext,
  useGetList,
  useRefresh,
  useRedirect,
  UrlField,
  FunctionField,
} from "react-admin";
import CreatePartner from "../partners/CreatePartner";
import DeletePartner from "./DeletePartner";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Wrapper from "../styles/store/StoreList"
import { Typography } from "@mui/material";

export const PartnerList = (props) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { data } = useGetList('stores', {
    pagination: { page: 1, perPage: 500 }
  });

  const storesArray = data;

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => {
      redirect('/stores/partners');
      refresh();
    }, 1800);
  }
  const editModal = () => {
    setEdit(false);
    setTimeout(() => {
      redirect('/stores/partners');
      refresh();
    }, 1800);

  }

  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [recordForEdit, setRecordForEdit] = React.useState(3);

  const CreateStoreButton = () => {
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => setOpen(true)}
        >
          Add New
          <AddIcon style={{
            marginLeft: "4px",
            marginTop: "2px",
            fontSize: "13px !important",
          }} />
        </Button>
      </>
    );
  };

  const EditStoreButton = ({ source }) => {
    const record = useRecordContext();

    const openInPopup = (record) => {
      setRecordForEdit(record);
      setEdit(true);
    };
    return (
      <div>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => {
            openInPopup(record);
          }}
        >
          <CloseIcon />
        </Button>
      </div>
    );
  };





  return (
    <Wrapper>
      <React.Fragment>
        <List actions={<CreateStoreButton />}  {...props} perPage={14} empty={false} >
          <Datagrid>
            <ImageField
              source="logoUrl"
              sx={{ maxWidth: 70 }}
              title="title"
              label="Logo"
              sortable={false}
            />
            <TextField source="name" sortable={true} className="shorter-text-partners" />

            <FunctionField source="categories" label="Categories" render={(x) => {
              if (x.categories.length) {
                const categoriesArr = x.categories.map((cat) => cat.name)
                return <Typography variant="body2">
                  {categoriesArr.join(", ")}
                </Typography>
              }
              return "--"
            }} />

            <UrlField source="url" target="_blank" label="URL Address" />

            <EditStoreButton label="Actions" />

          </Datagrid>
        </List>

        <Dialog open={open} onClose={() => setOpen(false)}>
          <CreatePartner items={storesArray} onEditPartner={closeModal} />
        </Dialog>
        <Dialog open={edit} onClose={() => setEdit(false)}>
          <DeletePartner recordForEdit={recordForEdit} onDeleteStore={editModal} />
        </Dialog>


      </React.Fragment>
    </Wrapper>
  );
};
