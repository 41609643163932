import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Button, localStorageStore } from "react-admin";

const Error = () => {
  const [visible, setVisible] = React.useState(false);
  const [text, setText] = React.useState("");

  const pop = localStorageStore().getItem("error.modal.open");
  const message = localStorageStore().getItem("error.modal.content");

  useEffect(() => {
    setText(message);
    setVisible(pop);
  }, [pop, message]);

  const handleClose = () => {
    setVisible(false);
    localStorageStore().setItem("error.modal.open", false);
    setTimeout(() => {
      setText("");
    }, 600);
  };
  

  return (
    <Dialog maxWidth={"sm"} open={visible} onClose={handleClose}>
      <DialogTitle>HTTP Request failed</DialogTitle>
      <DialogContent>
        <Typography align="center" color="red">{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ backgroundColor: "#BF2279", margin: "auto" }}
          onClick={handleClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Error;
