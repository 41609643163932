import * as React from "react";
import { List, Datagrid, TextField, DateField, Pagination } from "react-admin";
import DescriptionTextField from "../elements/DescriptionTextField";
import Wrapper from "../styles/wishlist/Wishlist";

export const NotificationList = (props) => {
  return (
    <Wrapper>
      <List
        pagination={<Pagination rowsPerPageOptions={[5, 10, 15]} />}
        {...props}
        perPage={10}
      >
        <Datagrid>
          <TextField source="store.name" label="Store" />
          <TextField source="title" />
          <DescriptionTextField source="description" />
          <DateField source="dateCreated" label="Date" />
        </Datagrid>
      </List>
    </Wrapper>
  );
};
