// import { Empty, fetchUtils } from "react-admin";
// import simpleRestProvider from 'ra-data-simple-rest';
import dataProvider from "./dataProvider";

export const myDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
    if (
      resource !== "stores" &&
      resource !== "deliveryCompany" &&
      resource !== "categories" &&
      resource !== "offers"
    ) {
      // fallback to the default implementation
      return dataProvider.create(resource, params);
    }
    // const newPictures = params.data.logoUrl;
    const newPictures =
      params.data.logoUrl || params.data.logo || params.data.iconUrl;

    if (resource === "offers") {
      const backgroundPictures = params.data.backgroundImageUrl;
      const logoPictures = params.data.logoUrl;

      if (!backgroundPictures && !logoPictures) {
        // fallback to the default implementation
        return dataProvider.create(resource, {
          ...params,
          data: {
            ...params.data,
            logoBase64: "",
            logoExtension: "",
            backgroundBase64: "",
            backgroundExtension: "",
          },
        });
      }
      if (!backgroundPictures) {
        return Promise.resolve(convertFileToBase64(logoPictures))
          .then((picture64) => ({
            // logoBase64: picture64.split(';')[1].split(',')[1],
            logoBase64: picture64,
            logoExtension: picture64.split(";")[0].split("/")[1],
          }))
          .then((transformedMyFile) =>
            dataProvider.create(resource, {
              ...params,
              data: {
                ...params.data,
                // myFile: transformedMyFile,
                logoBase64: transformedMyFile.logoBase64,
                logoExtension: transformedMyFile.logoExtension,
                backgroundBase64: "",
                backgroundExtension: "",
              },
            })
          );
      }
      if (!logoPictures) {
        return Promise.resolve(convertFileToBase64(backgroundPictures))
          .then((picture64) => ({
            logoBase64: picture64,
            logoExtension: picture64.split(";")[0].split("/")[1],
          }))
          .then((transformedMyFile) =>
            dataProvider.create(resource, {
              ...params,
              data: {
                ...params.data,
                logoBase64: "",
                logoExtension: "",
                backgroundBase64: transformedMyFile.logoBase64,
                backgroundExtension: transformedMyFile.logoExtension,
              },
            })
          );
      }
      const allPictures = [];
      allPictures[0] = logoPictures;
      allPictures[1] = backgroundPictures;
      console.log(allPictures);

      return Promise.all(allPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64) => ({
            src: picture64,
            logoBase64: picture64,
            logoExtension: picture64.slice(11, 15).replace(";", ""),
          }))
        )
        .then((newImages) =>
          dataProvider.create(resource, {
            ...params,
            data: {
              ...params.data,
              logoBase64: newImages[0].logoBase64,
              logoExtension: newImages[0].logoExtension,
              backgroundBase64: newImages[1].logoBase64,
              backgroundExtension: newImages[1].logoExtension,
            },
          })
        );
    }

    if (!newPictures) {
      // fallback to the default implementation
      return dataProvider.create(resource, {
        ...params,
        data: { ...params.data, logoBase64: "", logoExtension: "" },
      });
    }

    // const convertedBackgroundPictures = convertFileToBase64(backgroundPictures)
    // console.log(convertedBackgroundPictures)

    return Promise.resolve(convertFileToBase64(newPictures))
      .then((picture64) => ({
        logoBase64: picture64,
        logoExtension: picture64.split(";")[0].split("/")[1],
        anyFile: picture64,
      }))
      .then((transformedMyFile) =>
        dataProvider.create(resource, {
          ...params,
          data: {
            ...params.data,
            // myFile: transformedMyFile,
            logoBase64: transformedMyFile.logoBase64,
            logoExtension: transformedMyFile.logoExtension,
            backgroundBase64: "",
            backgroundExtension: "",
            fileNew: transformedMyFile.anyFile,
          },
        })
      );
  },
  update: (resource, params) => {
    if (
      resource !== "stores" &&
      resource !== "offers" &&
      resource !== "categories"
    ) {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }

    if (resource === "categories") {
      if (!params.data.iconUrls) {
        return dataProvider.update(resource, {
          ...params,
          data: { ...params.data, logoBase64: "", logoExtension: "" },
        });
      }
      const newPictures = params.data.iconUrls;

      if (!newPictures) {
        // fallback to the default implementation
        return dataProvider.update(resource, {
          ...params,
          data: { ...params.data, logoBase64: "", logoExtension: "" },
        });
      }

      return Promise.resolve(convertFileToBase64(newPictures))
        .then((picture64) => ({
          logoBase64: picture64,
          logoExtension: picture64.split(";")[0].split("/")[1],
          total: picture64,
        }))
        .then((transformedMyFile) =>
          dataProvider.update(resource, {
            ...params,
            data: {
              ...params.data,
              logoBase64: transformedMyFile.logoBase64,
              logoExtension: transformedMyFile.logoExtension,
            },
          })
        );
    }

    if (resource === "offers") {
      const backgroundPictures = params.data.backgroundImageUrls;
      const logoPictures = params.data.logoUrls;

      if (!backgroundPictures && !logoPictures) {
        // fallback to the default implementation
        return dataProvider.update(resource, {
          ...params,
          data: {
            ...params.data,
            logoBase64: "",
            logoExtension: "",
            backgroundBase64: "",
            backgroundExtension: "",
          },
        });
      }
      if (!backgroundPictures) {
        return Promise.resolve(convertFileToBase64(logoPictures))
          .then((picture64) => ({
            logoBase64: picture64,
            logoExtension: picture64.split(";")[0].split("/")[1],
          }))
          .then((transformedMyFile) =>
            dataProvider.update(resource, {
              ...params,
              data: {
                ...params.data,
                // myFile: transformedMyFile,
                logoBase64: transformedMyFile.logoBase64,
                logoExtension: transformedMyFile.logoExtension,
                backgroundBase64: "",
                backgroundExtension: "",
              },
            })
          );
      }
      if (!logoPictures) {
        return Promise.resolve(convertFileToBase64(backgroundPictures))
          .then((picture64) => ({
            logoBase64: picture64,
            logoExtension: picture64.split(";")[0].split("/")[1],
          }))
          .then((transformedMyFile) =>
            dataProvider.update(resource, {
              ...params,
              data: {
                ...params.data,
                logoBase64: "",
                logoExtension: "",
                backgroundBase64: transformedMyFile.logoBase64,
                backgroundExtension: transformedMyFile.logoExtension,
              },
            })
          );
      }

      const allPictures = [];
      allPictures[0] = logoPictures;
      allPictures[1] = backgroundPictures;
      console.log(allPictures);

      return Promise.all(allPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64) => ({
            src: picture64,
            logoBase64: picture64,
            logoExtension: picture64.slice(11, 15).replace(";", ""),
          }))
        )
        .then((newImages) =>
          dataProvider.update(resource, {
            ...params,
            data: {
              ...params.data,
              logoBase64: newImages[1].logoBase64,
              logoExtension: newImages[1].logoExtension,
              backgroundBase64: newImages[0].logoBase64,
              backgroundExtension: newImages[0].logoExtension,
            },
          })
        );
    }

    const newPictures = params.data.logoUrls;

    if (!newPictures) {
      // fallback to the default implementation
      return dataProvider.update(resource, {
        ...params,
        data: { ...params.data, logoBase64: "", logoExtension: "" },
      });
    }

    return Promise.resolve(convertFileToBase64(newPictures))
      .then((picture64) => ({
        logoBase64: picture64,
        logoExtension: picture64.split(";")[0].split("/")[1],
        total: picture64,
      }))
      .then((transformedMyFile) =>
        dataProvider.update(resource, {
          ...params,
          data: {
            ...params.data,
            logoBase64: transformedMyFile.logoBase64,
            logoExtension: transformedMyFile.logoExtension,
            myFile: transformedMyFile.total,
          },
        })
      );
  },
};

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });
