import React from "react";
import { localStorageStore } from "react-admin";
import "./App.css";
import { Admin, Resource, ListGuesser } from "react-admin";
import { StoreList } from "./components/store/StoreList";
import { PartnerList } from "./components/partners/Partners";
import StoreCreate from "./components/store/StoreCreate";
import StoreEdit from "./components/store/StoreEdit";
import { OffersList } from "./components/offers/OffersList";
import { CategoryList } from "./components/categories/CategoryList";
import { DeliveryList } from "./components/delivery/DeliveryList";
import {
  MostVisitedList,
  MostVisitedEdit,
} from "./components/most-visited/MostVisited";
import { MostSearchedList } from "./components/most-searched/MostSearchedList";
import { NewStoresList } from "./components/new-stores/NewStoresList";
import { ScrapingList } from "./components/scraping/ScrapingList";
import { WishList } from "./components/wishlist/WishList";

import ScrapingCreate from "./components/scraping/ScrapingCreate";
import { myDataProvider as dataProvider } from "./myDataProvider";
// import dataProviderApi from "./dataProviderApi";
import MyLayout from "./components/MyLayout";
import { NotificationList } from "./components/notifications/NotificationList";
import myTheme from "./components/styles/theme";
// import { ReactKeycloakProvider } from "@react-keycloak/web";
// import Keycloak from "keycloak-js";

// const dataProvider = combineDataProviders((resource) => {
//   switch (resource) {
//     case 'stores':
//     case 'stores/mostVisited':
//     case 'offers':
//     case 'categories':
//     case 'deliveryCompany':
//     case 'scrapingRules/all':
//     case 'wishlist':
//       return myDataProvider;
//     case 'users':
//       return dataProviderApi;
//     default:
//       throw new Error(`Unknown resource: ${resource}`);
//   }
// });

const App = () => {
  const store = localStorageStore().setup();
  console.log("29.11.2022");
  localStorageStore().setItem("error.modal.open", false);
  return (
    <Admin
      theme={myTheme}
      store={store}
      dataProvider={dataProvider}
      title="test"
      layout={MyLayout}
    >
      <Resource
        name="stores"
        list={StoreList}
        create={StoreCreate}
        edit={StoreEdit}
      />
      <Resource
        name="stores/ordered/1"
        list={MostVisitedList}
        edit={MostVisitedEdit}
      // create={MostVisitedCreate}
      />
      <Resource name="stores/ordered/2" list={MostSearchedList} />
      <Resource name="stores/ordered/3" list={NewStoresList} />
      <Resource name="offers" list={OffersList} />
      <Resource name="categories" list={CategoryList} />
      <Resource name="deliveryCompany" list={DeliveryList} />

      <Resource
        name="scrapingRules/all"
        list={ScrapingList}
        title="scraping"
        create={ScrapingCreate}
      />
      <Resource name="stores/dropdowns" list={ListGuesser} />
      <Resource name="wishlist" list={WishList} />
      <Resource name="notifications" list={NotificationList} />
      <Resource name="stores/partners" list={PartnerList} />
    </Admin>
  );
};

export default App;
