import * as React from "react";

import { MenuItemLink } from "react-admin";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';

const StoreSubmenu = (props) => {
  return (
    <div>
      <MenuItemLink
        to="/stores"
        primaryText="View All"
        leftIcon={<StorefrontIcon />}
        sx={{
          color: "#fff",
          fontSize: 14,
          marginTop: 5,
          "& .RaMenuItemLink-icon": {
            color: "#fff",
          "&.RaMenuItemLink-active": {
            fontWeight: 600
          }
          },
        }}
      />
      <MenuItemLink
        to={{
          pathname: '/stores/partners',
          // search: `partner=true`,
      }}
        primaryText="Partners"
        leftIcon={<PersonOutlineIcon />}
        sx={{
          color: "#fff",
          fontSize: 14,
          "& .RaMenuItemLink-icon": {
            color: "#fff",
          },
        }}
      />
      <MenuItemLink
        to="/stores/ordered/1"
        primaryText="Most Visited"
        leftIcon={<CircleOutlinedIcon />}
        sx={{
          color: "#fff",
          fontSize: 14,
          "& .RaMenuItemLink-icon": {
            color: "#fff",
          },
        }}
      />
       <MenuItemLink
        to="/stores/ordered/2"
        primaryText="Most Searched"
        leftIcon={<PageviewOutlinedIcon />}
        sx={{
          color: "#fff",
          fontSize: 14,
          "& .RaMenuItemLink-icon": {
            color: "#fff",
          },
        }}
      />
             <MenuItemLink
        to="/stores/ordered/3"
        primaryText="New Stores"
        leftIcon={<ModeStandbyIcon />}
        sx={{
          color: "#fff",
          fontSize: 14,
          "& .RaMenuItemLink-icon": {
            color: "#fff",
          },
        }}
      />
      <MenuItemLink
        to="/scrapingRules/all"
        primaryText="Scraping Rules"
        leftIcon={<PageviewOutlinedIcon />}
        sx={{
          color: "#fff",
          fontSize: 14,
          marginRight: 30,
          "& .RaMenuItemLink-icon": {
            color: "#fff",
          },
        }}
      />
      <MenuItemLink
        to="/wishlist"
        // to="/wishlist/all"
        primaryText="Wishlist"
        leftIcon={<FavoriteBorderOutlinedIcon />}
        sx={{
          color: "#fff",
          fontSize: 14,
          marginRight: 30,
          "& .RaMenuItemLink-icon": {
            color: "#fff",
          },
        }}
      />
 
    </div>
  );
};

export default StoreSubmenu;
