import React from 'react';
import {

  useDelete,
  Button,
} from "react-admin";
import Wrapper from "../styles/popups/Delete"
import modal from "../../assets/modal.png";

const NewStoreDelete = (props) => {
  const { recordForEdit } = props

  const [deleteOne] = useDelete();

  const storeDelete = (data) => {
    deleteOne("offers", { id: recordForEdit.id, data: { id: recordForEdit.id } });
    props.onDeleteStore()

  }
  const cancelDelete = () => {
    props.onOfferDelete()
  }

  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Delete</span>
      </div>
      {/* <Form id={storeId} className="test" sx={{ width: 550 }} mutationMode="optimistic" > */}
      <div className='modal-content'>

        <div className='modal-text'>You will not be able to recover "{recordForEdit.title}". Are you sure?</div>

        <Button
          color="primary"
          onClick={cancelDelete}
          style={{ border: "1px solid #bf2279", background: "#fff", color: "#bf2279", marginRight: 20 }}
        >Cancel
          {/* <img src={arrow} alt="arrow" style={{maxWidth: "15px"}}/>             */}

        </Button>
        <Button
          color="primary"
          onClick={storeDelete}
          style={{ marginTop: "0px" }}
        >Delete
          {/* <img src={arrow} alt="arrow" style={{maxWidth: "15px"}}/>             */}

        </Button>
      </div>
      {/* </Form> */}
    </Wrapper>
  );
};

export default NewStoreDelete;