import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  UrlField,
  Edit,
  Button,
  SimpleForm,
  DateInput,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  useRefresh,
  useGetList,
  useRedirect,
  useRecordContext,
} from "react-admin";

import MostSearchedCreate from "./MostSearchedCreate";
import MostSearchedDelete from "./MostSearchedDelete";
import MostSearchedOrder from "./MostSearchedOrder";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Wrapper from "../styles/most-visited/MostVisited"
import arrow from "../../assets/arrow.png"



export const MostSearchedList = () => {
  const { data } = useGetList('stores', {
    pagination: { page: 1, perPage: 170 }
  })
  const allStores = data;


  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState(false);
  const [deleteStore, setDeleteStore] = React.useState(false);
  const [recordForEdit, setRecordForEdit] = React.useState(3);
  const refresh = useRefresh();
  const redirect = useRedirect();

  const closePopup = () => {
    setOpen(false);
    setTimeout(() => {
      refresh();
    }, 1000);

  }
  const closeModal = () => {
    setDeleteStore(false);
    setTimeout(() => {
      refresh();
    }, 1000);
  }

  const orderModal = () => {
    setOrder(false);
    setTimeout(() => {
      redirect('/stores/ordered/2');
      refresh();
    }, 1400);
    setTimeout(() => {
      setRecordForEdit(0)
    }, 1800
    )
  }


  const CreateStoreButton = (props) => {
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => setOpen(true)}
        >
          Add New
          <AddIcon style={{
            marginLeft: "4px",
            marginTop: "2px",
            fontSize: "13px !important",
          }} />
        </Button>
      </>
    );
  };

  const EditOrderButton = ({ source }) => {
    const record = useRecordContext();
    const openInPopup = record => {
      setRecordForEdit(record)
      setOrder(true)
    }
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{ marginTop: "0px" }}
        >
          <img src={arrow} alt="arrow" style={{ maxWidth: "15px" }} />

        </Button>
      </>
    );
  };

  const DeleteStoreButton = ({ source }) => {
    const record = useRecordContext();
    const openInPopup = record => {
      setRecordForEdit(record)
      setDeleteStore(true)
    }
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{ marginTop: "0px" }}
        >
          <CloseIcon />

        </Button>
      </>
    );
  };



  const postRowStyle = (record, index) => ({// eslint-disable-next-line
    outline: record.id == recordForEdit.id ? '1px solid #ff6d44' : ''
  })

  return (
    <Wrapper>
      <List actions={<CreateStoreButton />} perPage={14}  >
        <Datagrid rowStyle={postRowStyle} sx={{
          textDecoration: 'none',
          "& .MuiTableRow-root": {
            borderRadius: "20px",
          },
          "& .RaAppBar-menuButton": {
            paddingLeft: 15
          }
        }}>
          <TextField source="position" label="Order Id" sortable={false} />
          <ImageField
            source="logoUrl"
            sx={{
              maxWidth: 70,
              borderRadius: "10 10 0 0"
            }}
            title="title"
            label="Logo"
            sortable={false}
          />
          <TextField source="name" sortable={false} className="shorter-text-wishlist" />
          <UrlField source="url" label="URL Address" sortable={false} className="shorter-text-wishlist" />


          <EditOrderButton label="Actions" />
          <DeleteStoreButton label=" " />


        </Datagrid>
      </List>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <MostSearchedCreate stores={allStores} onAddStore={closePopup} />
      </Dialog>
      <Dialog open={deleteStore} onClose={() => setDeleteStore(false)}>
        <MostSearchedDelete recordForEdit={recordForEdit} onDeleteStore={closeModal} />
      </Dialog>

      <Dialog open={order} onClose={() => setOrder(false)}>
        <MostSearchedOrder recordForEdit={recordForEdit} onEditOrder={orderModal} />
      </Dialog>

    </Wrapper>
  )
};

export const MostSearchedEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="name" />
        <TextInput source="url" />
        <TextInput source="logoUrl" />
        <BooleanInput source="isPartner" />
        <ArrayInput source="categories">
          <SimpleFormIterator>
            <TextInput source="id" />
            <TextInput source="name" />
            <TextInput source="iconUrl" defaultValue="" />
            <TextInput source="position" defaultValue="" />
          </SimpleFormIterator>
        </ArrayInput>
        <DateInput source="dateAdded" />
        <DateInput source="dateEdited" />
      </SimpleForm>
    </Edit>
  );
};






