import * as React from "react";
import { useRecordContext } from 'react-admin'; 
 

const MyArrayField = ({ source }) => {
    const record = useRecordContext();
    // const shortText = record[source].substring(0,10);
   
    return record ? (
        <ul className="array-list">
        {record.categories.map((item, index) => (
            <span key={item.name}>{(index ? ', ' : '') + item.name}</span>
        ))}
    </ul>
    ) : null;
}

export default MyArrayField;