import React, { useState, useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  useUpdate,
  useRefresh,
  AutocompleteInput,
} from "react-admin";
import Wrapper from "../styles/scraping/ScrapingEdit"
import modal from "../../assets/modal.png";
import UserService from "../../UserService";
import { fields_url, elements_url, stores_url } from "../../constants";

const ScrapingEdit = (props) => {

  const { recordForEdit } = props
  const [storeId, setStoreId] = React.useState(1);

  const refresh = useRefresh();

  const [elements, setElements] = useState([]);
  const [fields, setFields] = useState([]);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(elements_url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "authorization": "Bearer " + UserService.getToken()
          },
        });
        const json = await response?.json();
        let results = json;

        setElements(results);

      } catch (error) {
        console.log("error", error)
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(fields_url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "authorization": "Bearer " + UserService.getToken()
          },
        });
        const json = await response?.json();
        let resultsFields = json;

        setFields(resultsFields);

      } catch (error) {
        console.log("error", error)
      }
    }
    fetchData();
  }, []);

  const [stores, setStores] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(stores_url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "authorization": "Bearer " + UserService.getToken()
          },
        });
        const json = await response?.json();
        let results = json;
        setStores(results);
      } catch (error) {
        console.log("error", error)
      }
    }
    fetchData();
  }, []);

  const storesArray = stores





  useEffect(() => {
    setStoreId(recordForEdit.id)

  }, [recordForEdit]);

  const [update] = useUpdate();
  const scrapingEdit = (data) => {
    console.log(data);
    update("scrapingRules/update", { id: recordForEdit.id, data: data, previousData: recordForEdit });
    // update(`/stores/${storeId}`, {id: recordForEdit.id, data: data, previousData: recordForEdit});
    props.onEditStore()
    refresh();
  }
  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Edit Rule </span>
      </div>
      <Edit id={storeId} className="test" sx={{ width: 550 }}  >
        <SimpleForm onSubmit={scrapingEdit}>
          {/* <div className='store-name'><span className='store-name-title'>store name</span><TextField source="store.name" className='store-name-field' /></div> */}
          <AutocompleteInput source="store.id" choices={storesArray} label="store name" />

          <AutocompleteInput source="elementType" choices={elements} optionText="result" optionValue="result" label="element type" />
          <AutocompleteInput source="fieldType" choices={fields} optionText="result" optionValue="result" label="field type" />
          <TextInput source="xpath" multiline label="xpath" />


        </SimpleForm>
      </Edit>
    </Wrapper>
  );
};

export default ScrapingEdit;
