import * as React from "react";
import {
  Create,
  SimpleForm,
  ImageInput,
  ImageField,
  TextInput,
  useCreate
} from "react-admin";

import AddIcon from "@mui/icons-material/Add";
import Wrapper from "../styles/delivery/DeliveryCreate"
import modal from "../../assets/modal.png";

const DeliveryCreate = props => {
  const [create] = useCreate();
  const deliverySave = (data) => {
    console.log(data);
    create("deliveryCompany", { data });
    // notify(`Changes saved`);
    props.onAddDelivery();



  };
  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>New Delivery Service</span>
      </div>
      <Create className="test" sx={{ width: 480 }}>
        <SimpleForm onSubmit={deliverySave}>
          {/* <TextInput disabled source="id" /> */}
          <ImageInput
            source="logo"
            label="Logo"
            accept="image/*"
            placeholder={<AddIcon />}
          >
            <ImageField source="logoBase64" title="title" />
          </ImageInput>
          <TextInput source="name" />
          <TextInput source="url" label="Main URL Address" />
          <TextInput source="checkUrl" label="Delivery Check URL " />


        </SimpleForm>
      </Create>
    </Wrapper>
  );
};

export default DeliveryCreate;