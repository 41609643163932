import React, { memo } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  useRefresh,
  Button,
  useRecordContext,
  useGetList,
  Filter,
  SearchInput,
} from "react-admin";
import ScrapingCreate from "./ScrapingCreate";
import ScrapingEdit from "./ScrapingEdit";
import ScrapingDelete from "./ScrapingDelete";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import Wrapper from "../styles/scraping/ScrapingList";
import CloseIcon from "@mui/icons-material/Close";

export const ScrapingList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [deleteStore, setDeleteStore] = React.useState(false);
  const [recordForEdit, setRecordForEdit] = React.useState(3);
  const refresh = useRefresh();

  const { data } = useGetList("stores", {
    pagination: { page: 1, perPage: 600 },
  });
  const allStores = data;

  const closeModal = () => {
    setDeleteStore(false);
    setTimeout(() => {
      refresh();
    }, 1000);
  };

  const ListActions = memo(() => [
    <Button
      style={{ margin: 0, marginBottom: 10, marginRight: 10 }}
      color="primary"
      aria-label="create"
      onClick={() => setOpen(true)}
    >
      Add New
      <AddIcon
        style={{
          marginLeft: "4px",
          marginTop: "2px",
          fontSize: "13px !important",
        }}
      />
    </Button>,
  ]);

  const EditRuleButton = ({ source }) => {
    const record = useRecordContext();

    const openInPopup = (record) => {
      setRecordForEdit(record);
      setEdit(true);
    };
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => {
            openInPopup(record);
          }}
        >
          <EditOutlinedIcon style={{ marginRight: "4px" }} />
        </Button>
      </>
    );
  };

  const DeleteStoreButton = ({ source }) => {
    const record = useRecordContext();
    const openInPopup = (record) => {
      setRecordForEdit(record);
      setDeleteStore(true);
    };
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{ marginTop: "0px" }}
        >
          <CloseIcon />
        </Button>
      </>
    );
  };
  const postRowStyle = (record, index) => ({// eslint-disable-next-line
    outline: record.id == recordForEdit.id ? "1px solid #ff6d44" : "",
  });

  return (
    <Wrapper>
      <React.Fragment>
        <List
          actions={<ListActions />}
          filters={
            <Filter>
              <SearchInput
                style={{
                  position: "absolute",
                  left: 20,
                  bottom: 1,
                  backgroundColor: "#FFF",
                  borderRadius: 10,
                }}
                variant="outlined"
                source="search"
                size="small"
                alwaysOn
              />
            </Filter>
          }
          perPage={14}
        >
          <Datagrid rowStyle={postRowStyle}>
            <TextField source="store.name" sortable={true} label="Store" />
            <TextField source="fieldType" sortable={true} label="field_type" />
            <TextField
              source="elementType"
              sortable={true}
              label="element_type"
            />
            <TextField
              source="xpath"
              sortable={true}
              label="xpath"
              className="shorter-text-offer"
            />

            <DateField
              source="dateCreated"
              options={{
                weekday: "short",
                year: "numeric",
                month: "long",
                day: "numeric",
              }}
              label="Date"
              sortable={true}
            />

            <EditRuleButton label="Actions" />
            <DeleteStoreButton label=" " />
          </Datagrid>
        </List>

        <Dialog open={open} onClose={() => setOpen(false)}>
          <ScrapingCreate
            {...props}
            stores={allStores}
            onAddStore={() => setOpen(false)}
          />
        </Dialog>

        <Dialog open={edit} onClose={() => setEdit(false)}>
          <ScrapingEdit
            stores={allStores}
            recordForEdit={recordForEdit}
            onEditStore={() => setEdit(false)}
          />
        </Dialog>

        <Dialog open={deleteStore} onClose={() => setDeleteStore(false)}>
          <ScrapingDelete
            recordForEdit={recordForEdit}
            onDeleteStore={closeModal}
          />
        </Dialog>
      </React.Fragment>
    </Wrapper>
  );
};
