import React from "react";
import {
  Create,
  SimpleForm, 
  useUpdate,
  AutocompleteInput
} from "react-admin";
import Wrapper from "../styles/most-visited/MostVisitedCreate";
import modal from "../../assets/modal.png";

const NewStoreCreate = (props) => {
  const arr =  props.stores; 
  const storesFiltered = arr.filter((el)=> el.isDeleted !== true && el.isVerified === true );

 

  const [update] = useUpdate();
  const postSave = (data) => {
    console.log(data);
   const storeId = data.id;
   
    update("ordering/addStore",{ id: storeId,data:{orderTypeId:3, position:2}} );   
    props.onAddStore();
     
  };
 
  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>New Store Create</span>
      </div>
      <Create className="test" sx={{ width: 480 }} mutationMode="optimistic">
        <SimpleForm onSubmit={postSave}>
          <AutocompleteInput    source="id" choices={storesFiltered} />
        </SimpleForm>
      </Create>
    </Wrapper>
  );
};
export default NewStoreCreate;