import { Layout } from "react-admin";
import Error from "./error/Error";
import MyAppBar from "./MyAppBar";
import MySidebar from "./sidebar/MySidebar";

const MyLayout = (props) => (
  <>
  <Layout {...props} appBar={MyAppBar} sidebar={MySidebar} />
  <Error />
  </>
);

export default MyLayout;
