import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  DateField,
  BooleanField,
  UrlField,
  Button,
  useGetList,
  useRecordContext,
  useRedirect,
  useRefresh,
  FunctionField
} from "react-admin";

import Wrapper from "../styles/offers/OffersList";
import OffersCreate from "./OffersCreate";
import OfferEdit from "./OfferEdit";
import OfferOrder from "./OfferOrder";
import OfferDelete from "./OfferDelete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import arrow from "../../assets/arrow.png";

export const OffersList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [order, setOrder] = React.useState(false);
  const [deleteStore, setDeleteStore] = React.useState(false);
  const redirect = useRedirect();
  const refresh = useRefresh();

  const [recordForEdit, setRecordForEdit] = React.useState(3);

  const postRowStyle = (record, index) => ({
    // eslint-disable-next-line
    outline: record.id == recordForEdit.id ? "1px solid #ff6d44" : "",
  });

  const { data } = useGetList("stores", {
    pagination: { page: 1, perPage: 600 },
  });
  const allStores = data;

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => {
      redirect("/offers");
      refresh();
    }, 1600);
  };
  const editModal = () => {
    setEdit(false);
    setTimeout(() => {
      redirect("/offers");
      refresh();
    }, 1600);
    setTimeout(() => {
      setRecordForEdit(0);
    }, 2000);
  };

  const orderModal = () => {
    setOrder(false);
    setTimeout(() => {
      redirect("/offers");
      refresh();
    }, 1400);
    setTimeout(() => {
      setRecordForEdit(0);
    }, 1800);
  };

  const closeDeleteModal = () => {
    setDeleteStore(false);
    setTimeout(() => {
      refresh();
    }, 1000);
  };

  const CreateOffersButton = () => {
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => setOpen(true)}
        >
          Add New
          <AddIcon
            style={{
              marginLeft: "4px",
              marginTop: "2px",
              fontSize: "13px !important",
            }}
          />
        </Button>
      </>
    );
  };

  const EditOrderButton = ({ source }) => {
    const record = useRecordContext();
    const openInPopup = (record) => {
      setRecordForEdit(record);
      setOrder(true);
    };
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{ marginTop: "0px" }}
        >
          <img src={arrow} alt="arrow" style={{ maxWidth: "15px" }} />
        </Button>
      </>
    );
  };

  const EditOffersButton = ({ source }) => {
    const record = useRecordContext();
    const openInPopup = (record) => {
      setRecordForEdit(record);
      setEdit(true);
    };
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{ marginTop: "5px" }}
        >
          <EditOutlinedIcon
            style={{ marginRight: "4px", marginBottom: "4px" }}
          />
        </Button>
      </>
    );
  };
  const DeleteOfferButton = ({ source }) => {
    const record = useRecordContext();
    const openInPopup = (record) => {
      setRecordForEdit(record);
      setDeleteStore(true);
    };
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{ marginTop: "0px" }}
        >
          <CloseIcon />
        </Button>
      </>
    );
  };

  return (
    <Wrapper>
      <List actions={<CreateOffersButton />}  {...props} empty={false}>
        <Datagrid rowStyle={postRowStyle} >
          <TextField source="position" label="Order Id" sortable={false} />

          <FunctionField source="product.oldPrice" label="Old Price" render={(x) => { // ugly but can be fixed if solution is discovered
            const logoUrlString = x.logoUrl ? "logoUrl" : "store.logoUrl";
            return <ImageField
              source={logoUrlString}
              sx={{ maxWidth: 70, borderRadius: "10 10 0 0" }}
              title="title"
              label="Logo"
              sortable={false}
            />
          }} />
          <TextField
            source="store.name"
            label="Store"
            className="shorter-text-extra-small"
          />
          {/* <FunctionField label="Store" sortBy="store.name" sx={{ textOverflow: "ellipsis" }} render={
                record => `${record.store.name.substring(0,10)}`
            } className="store-name" sortable={false} /> */}

          <ImageField
            source="backgroundImageUrl"
            sx={{ maxWidth: 70, borderRadius: "10 10 0 0" }}
            label="Image"
            sortable={false}
          />

          <TextField
            source="title"
            label="Title"
            className="shorter-text-offer"
            sortable={false}
          />
          <TextField
            source="description"
            label="Description"
            className="shorter-text-small"
            sortable={false}
          />
          <UrlField
            source="promoUrl"
            className="shorter-text-small"
            label="Promo URL"
            sortable={false}
          />
          <BooleanField source="isActive" label="Active" sortable={false} />
          <DateField
            source="startDate"
            options={{
              // weekday: "short",
              year: "2-digit",
              month: "short",
              day: "numeric",
            }}
            label="Offer Start"
            sortable={false}
          />
          <DateField
            source="endDate"
            options={{
              // weekday: "short",
              year: "2-digit",
              month: "short",
              day: "numeric",
            }}
            label="Offer End"
            sortable={false}
          />
          <EditOrderButton label=" " />
          <EditOffersButton label="Actions" />
          <DeleteOfferButton />
        </Datagrid>
      </List>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <OffersCreate {...props} stores={allStores} onAddOffer={closeModal} />
      </Dialog>

      <Dialog open={edit} onClose={() => setEdit(false)}>
        <OfferEdit
          recordForEdit={recordForEdit}
          stores={allStores}
          onEditOffer={editModal}
        />
      </Dialog>

      <Dialog open={order} onClose={() => setOrder(false)}>
        <OfferOrder
          recordForEdit={recordForEdit}
          stores={allStores}
          onEditOrder={orderModal}
        />
      </Dialog>
      <Dialog open={deleteStore} onClose={() => setDeleteStore(false)}>
        <OfferDelete
          recordForEdit={recordForEdit}
          onDeleteStore={closeDeleteModal}
        />
      </Dialog>
    </Wrapper>
  );
};
