import { styled } from "@mui/system";

const Wrapper = styled("div")({
    color: "darkslategray",
    padding: 8,
    borderCollapse: "separate",
    borderSpacing: 0,
    ".MuiTableRow-root": {
      borderRadius: 20,
      boxShadow: "0px 5px 10px 2px #dee5e7",
      // backgroundColor: "#fff",
    },
    ".MuiTableRow-root td:nth-child(2)": {
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    ".MuiTableRow-root td:last-child": {
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: 20,
      paddingRight: "25px !important"
    },
    ".MuiTableRow-root td:last-child span": {
    
      paddingLeft: 0,
    },
    ".MuiTableRow-root td:nth-child(6)": {
      textAlign: "center",
    },
    ".MuiTableRow-root td:nth-child(7)": {
      textAlign: "center",
    },
    ".MuiTableRow-root td:nth-child(8)": {
      textAlign: "center",
    },
    ".css-rqglhn-MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0 10px",
    },
    ".MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    ".MuiTableCell-head": {
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
    ".MuiTableRow-head": {
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
    ".MuiTableRow-hover:hover": {
      backgroundColor: "transparent !important",
      outline: "1px solid #ff6d44",
      color: "#BF2279",
    },
    ".MuiTableRow-hover:hover td": {
      color: "#BF2279",
    backgroundColor: "#fff !important",

    },
    ".MuiTableRow-root .MuiBox-root ": {
      maxHeight: 35,
    },
    ".MuiTableRow-root .MuiBox-root img": {
      maxHeight: 26,
    },
    ".column-undefined": {
      width: 0,
      padding: 0,
    },
    ".column-undefined button": {
      justifyContent: "flex-start",
      marginLeft: "3px",
    },
    ".column-undefined a span": {
      borderRight: "1px solid #BF2279",
    },
    ".column-undefined a span svg": {
      marginRight: "3px",
    },
    ".MuiBox-root img": {
      width: "auto",
      maxWidth: 62
    },
    ".MuiButton-root:focus::after ": {
      opacity: 0,
    },
    " .MuiTableCell-head.column-dateCreated": {
      textAlign: "center",
    },
    "th.column-undefined span": {
      paddingLeft: "15px !important"
    }
  });


  export default Wrapper;