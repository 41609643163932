import React, { useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  NumberInput,
  useUpdate,
} from "react-admin";
import Wrapper from "../styles/offers/OfferEdit"
import modal from "../../assets/modal.png";

const OfferOrder = (props) => {
  const { recordForEdit } = props
  const [storeId, setStoreId] = React.useState(1)

  useEffect(() => {
    setStoreId(recordForEdit.id)
  }, [recordForEdit]);

  const [update] = useUpdate();

  const offerEdit = (data) => {
    const selectedPosition = data.position;
    update("categories/position", { id: recordForEdit.id, data: { newPosition: selectedPosition } });
    props.onEditOrder()
  }

  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Change position</span>
      </div>
      <Edit id={storeId} className="test" sx={{ width: 550 }} mutationMode="optimistic" >
        <SimpleForm onSubmit={offerEdit}>
          <NumberInput
            source="position"
            label="Order Id"
          />
        </SimpleForm>
      </Edit>
    </Wrapper>
  );
};

export default OfferOrder;