import { styled } from "@mui/system";

const Wrapper = styled("div")({
    ".MuiTextField-root": {
      width: 560,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    ".create-page": {
      width:550 
    },
    ".MuiTextField-root label": {
      position: "relative",
      transform: "none",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "rgba(0,0,0,0.6)",
    },
    ".RaLabeled-label span, .MuiFormControlLabel-label": {
      fontSize: "14px !important",
      fontWeight: 600,
      color: "rgba(0,0,0,0.6)",
    },
    ".ra-input-logoUrl": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "spaceBetween",
      alignItems: "center",
    },
    ".ra-input-logoUrl .RaFileInput-dropZone": {
      width: 26,
      border: "1px solid #c4c4c4",
      borderRadius: 5,
      marginLeft: 158,
      background: "transparent",
    },
    ".MuiFormControlLabel-root": {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      width: 285,
      marginLeft: 0,
    },
    ".MuiFilledInput-root:before": {
      borderBottom: "none",
    },
    ".MuiFilledInput-root input": {
      background: "#fff",
      border: "1px solid #c4c4c4",
      borderRadius: 41,
      padding: 10,
    },
    ".MuiFilledInput-root:hover, .MuiFilledInput-root:focus": {
      borderRadius: 41,
    },
    ".MuiAutocomplete-root .MuiFilledInput-root": {
      background: "#fff",
      marginLeft: "95px !important",
      width: "289px !important",
      marginRight: "41px !important",
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important'
    },
    // ".css-i6tejy-MuiInputBase-root-MuiFilledInput-root": {
    //   marginLeft: "95px !important",
    //   width: "282px !important",
    //   marginRight: "-20px !important"
    // },
    ".css-1r5okjt-RaCreate-root": {
      width: 550,
    },
    ".MuiInputLabel-root": {
      minWidth: 84,
    },
    ".RaLabeled-label": {
      width: 75,
    },
    ".css-1xuwtk8-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked": {
      color: "#fff",
    },
    ".MuiSwitch-switchBase input[type=checkbox]": {
      color: "#BF2279",
    },
    "input[type=checkbox]:checked": {
      color: "#BF2279",
    },
    "input[type=checkbox]:checked:before": {
      color: "#BF2279",
    },
    "input[type=checkbox i] ": {
      backgroundColor: "#BF2279",
    },
    ".RaToolbar-defaultToolbar .MuiButton-root": {
      backgroundColor: "#BF2279 !important",
      borderRadius: 20,
      color: "#fff",
      textTransform: "capitalize",
    },
    ".RaToolbar-defaultToolbar ": {
      justifyContent: "flex-end !important",
    },
    ".modal-top": {
      width: "550px !important",
      height: "auto",
      position: "relative",
    },
    ".modal-top img": {
      width: "100%",
      height: "auto",
    },
    ".modal-top span": {
      position: "absolute",
      top: 15,
      left: 38,
      color: "#fff",
      fontWeight: 500,
      fontSize: 16,
    },
    ".MuiInputBase-root:before": {
      borderBottom: "none",
    },
    ".MuiFilledInput-root input::after": {
      borderBottom: "1px solid #c4c4c4",
    },
    ".css-i6tejy-MuiInputBase-root-MuiFilledInput-root:after": {
      borderBottom: "none",
    },
    ".css-1xuwtk8-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
      {
        backgroundColor: "#BF2279",
        opacity: 1,
      },
    ".MuiSwitch-thumb": {
      width: 12,
      height: 12,
      marginTop: 4,
      marginLeft: 4,
    },
    ".RaFileInput-preview": {
      marginLeft: 20,
    },
    ".RaFileInput-preview img": {
      width: "100%",
    },
    // ".RaAutocompleteInput-textField .MuiFilledInput-root": {
    //   width: 315,
    //   paddingTop:"0 !important",
    //   marginRight: 23
    // },
    ".RaAutocompleteInput-textField .MuiFilledInput-root input": {
      padding: "10px !important"
    },
    ".RaAutocompleteInput-textField p": {
      display: "none"
    },
    ".RaAutocompleteInput-textField .MuiFilledInput-root:after": {
      borderBottom: "none !important"
    },
    ".MuiFilledInput-root:hover:before": {
      borderBottom: "none !important"
    },
    "textarea": {
      marginRight: "-38px !important",
      width: "268px !important",
      padding: '10px !important',
      marginLeft: '95px !important'
    },
    ".MuiInputBase-multiline": {
      paddingTop: "0px !important",
      backgroundColor: "#fff !important"
    },
    ".ra-input-xpath .MuiFilledInput-root:hover": {
      backgroundColor: "#fff !important"
    },
    ".ra-input-xpath .MuiFilledInput-root:after": {
      borderBottom: "none !important"
    }
  });


  export default Wrapper;