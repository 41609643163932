import * as React from "react";
import { useRecordContext } from 'react-admin'; 
 

const ArrayTextField = ({ source }) => {
    const record = useRecordContext();
    const shortText = record[source].substring(0,10);
   
    return record ? (
        <span sx={{ textDecoration: 'none', color: '#000'}}>
            {shortText }            
        </span>
    ) : null;
}

export default ArrayTextField;