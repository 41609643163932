import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
} from "react-admin";
import { FunctionField } from "react-admin/dist";
import { formatThousands } from "../../utils";

import Wrapper from "../styles/wishlist/Wishlist";

export const WishList = () => {

  const handleRedirect = (id, resource, record) => {
    const url = record.product?.productUrl
    url && window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <Wrapper>
      <React.Fragment>
        <List perPage={14}>
          {/* <List actions={<CreateStoreButton />}> */}

          <Datagrid rowClick={handleRedirect}>
            <TextField source="product.id" label="Id" />
            <TextField
              source="product.store.name"
              label="Store"
              className="shorter-text-offer"
            />
            <ImageField
              source="product.imageUrl"
              label="View Picture"
              sortable={true}
            />
            <TextField
              source="product.name"
              label="Product"
              className="shorter-text-wishlist"
            />
          <FunctionField source="product.oldPrice" label="Old Price" render={(x) => {
              const price = x.product?.oldPrice ? formatThousands(x.product?.oldPrice.toFixed(2)) : ""
              return price
            }} />
            <FunctionField source="product.price" label="Discounted Price" render={(x) => {
              const price = x.product?.price ? formatThousands(x.product?.price.toFixed(2)) : ""
              return price
            }} />
          </Datagrid>
        </List>
      </React.Fragment>
    </Wrapper>
  );
};
