import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  ImageField,
  SimpleForm,
  DateInput,
  TextInput,
  BooleanInput,
  ArrayInput,
  UrlField,
  SimpleFormIterator,
  Button,
  useRefresh
} from "react-admin";

import Wrapper from "../styles/delivery/DeliveryList"
import AddIcon from "@mui/icons-material/Add";
import DeliveryCreate from "./DeliveryCreate";
import Dialog from "@mui/material/Dialog";

export const DeliveryList = () => {
  const [open, setOpen] = React.useState(false);

  const CreateDeliveryButton = () => {
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => setOpen(true)}
        >
          Add New
          <AddIcon style={{
            marginLeft: "4px",
            marginTop: "2px",
            fontSize: "13px !important",
          }} />
        </Button>
      </>
    );
  };
  const refresh = useRefresh();
  const refreshPage = () => {
    setOpen(false);
    setTimeout(() => {
      refresh();
    }, 1000);
  }


  return (
    <Wrapper>
      <List actions={<CreateDeliveryButton />}  >
        <Datagrid rowClick="edit" sx={{
          textDecoration: 'none',
          "& .MuiTableRow-root": {
            borderRadius: "20px",
          },
          "& .RaAppBar-menuButton": {
            paddingLeft: 15
          }
        }}>
          <TextField source="id" />

          <TextField source="name" />
          <ImageField
            source="logo"
            sx={{ maxWidth: 70 }}
            title="title"
            label="Logo"
          />
          <UrlField source="url" label="Main URL Address" className="shorter-text-offer" />
          <UrlField source="checkUrl" label="Delivery Check URL Address" className="shorter-text-partners" sortable={false} />






        </Datagrid>
      </List>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DeliveryCreate onAddDelivery={refreshPage} />
      </Dialog>

    </Wrapper>
  )
};

export const DeliveryEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="name" />
        <TextInput source="url" />
        <TextInput source="logoUrl" />
        <BooleanInput source="isPartner" />
        <ArrayInput source="categories">
          <SimpleFormIterator>
            <TextInput source="id" />
            <TextInput source="name" />
            <TextInput source="iconUrl" defaultValue="" />
            <TextInput source="position" defaultValue="" />
          </SimpleFormIterator>
        </ArrayInput>
        <DateInput source="dateAdded" />
        <DateInput source="dateEdited" />
      </SimpleForm>
    </Edit>
  );
};



