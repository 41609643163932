import { styled } from "@mui/system";


const Wrapper = styled("div")({
  color: "darkslategray",
  padding: 8,
  borderCollapse: "separate",
  borderSpacing: 0,
  ".MuiTableRow-root": {
    borderRadius: 20,
    boxShadow: "0px 5px 10px 2px #dee5e7",
    // backgroundColor: "#fff",
  },
  ".MuiTableRow-root td:nth-child(2)": {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  ".MuiTableRow-root td:last-child": {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    marginRight: 20,
  },
  ".MuiTableRow-root td:nth-child(8)": {
    textAlign: "center",
  },
  ".css-rqglhn-MuiTable-root": {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
  },
  ".MuiPaper-root": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  ".MuiTableCell-head": {
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
  },
  ".MuiTableRow-root th:nth-child(2)": {
    paddingLeft: "26px !important"
  },
  ".MuiTableRow-root th:nth-child(5)": {
    textAlign: "center"
  },
  ".MuiTableRow-head": {
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
  },
  ".MuiTableRow-hover:hover": {
    backgroundColor: "transparent !important",
    outline: "1px solid #ff6d44",
    color: "#BF2279",
  },
  ".MuiTableRow-hover:hover td": {
    color: "#BF2279",
    backgroundColor: "#fff !important",

  },
  ".MuiTableRow-root .MuiBox-root ": {
    maxHeight: 35,
  },
  ".MuiTableRow-root .MuiBox-root img": {
    maxHeight: 26,
  },
  ".column-undefined": {
    maxWidth: 20,
    padding: 0,
  },
  ".column-undefined button": {
    justifyContent: "flex-start",
    marginLeft: "3px",
    minWidth: 15
  },
  ".column-undefined a span": {
    borderRight: "1px solid #BF2279",
  },
  ".column-undefined a span svg": {
    marginRight: "3px",
  },
  ".MuiBox-root img": {
    width: "auto",
    maxWidth: 62
  },
  ".MuiButton-root:focus::after ": {
    opacity: 0,
  },
  ".MuiTableCell-root.column-undefined button:hover": {
    backgroundColor: "transparent !important",
  },
  ".MuiTableCell-root.column-undefined button svg": {
    color: "#BF2279",
  },
  ".column-logoUrl": {
    minWidth: 42
  },
  ".css-lwyovy-MuiToolbar-root-RaTopToolbar-root": {
    display: 'none'
  },
  ".css-gp0tzt-MuiToolbar-root-RaTopToolbar-root, .css-1lj9bzt": {
    display: "none"
  },
  ".column-product.store.id span": {
    paddingLeft: 8
  },
  ".MuiTableRow-root td:nth-child(6)": {
    color: "#9a9a9a !important",
    textDecoration: "line-through !important",
    textAlign: "center"
  },
  ".MuiTableRow-root td:nth-child(4) span": {
    display: "block",
    width: 70,
    textAlign: "right"
  },
  ".MuiTableRow-root td:nth-child(7)": {
    textAlign: "center"
  },
  ".MuiTableRow-root td:nth-child(7) span": {
    display: "block",
    width: 70,
    textAlign: "right"
  },
  ".MuiTableRow-root td:nth-child(4)": {
    width: "70px !important",
    paddingRight: "20px !important",

  },
  ".MuiTableRow-root td:nth-child(4):hover .RaImageField-image": {
    transform: "scale(5)",
    zIndex: "3 !important",
    border: "1px solid #efe8e8 !important",
    borderRadius: "3px !important",
    position: "absolute",
    top: 34,
    right: 30,
    backgroundColor: "#fff !important"
  },
  ".MuiTableRow-root td:nth-child(4) .MuiBox-root": {
    position: "relative"
  }
});

export default Wrapper;