import React, { useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  NumberInput,
  useUpdate,
} from "react-admin";
import Wrapper from "../styles/offers/OfferOrder"
import modal from "../../assets/modal.png";

const OfferOrder = (props) => {
  const { recordForEdit } = props
  const [storeId, setStoreId] = React.useState(1)

  console.log(recordForEdit);
  useEffect(() => {
    setStoreId(recordForEdit.id)

  }, [recordForEdit]);

  const [update] = useUpdate();


  const offerEdit = (data) => {
    console.log(data)
    const selectedPosition = data.position;
    update("offers/updatePosition", { id: recordForEdit.id, data: { offerId: recordForEdit.id, newPosition: selectedPosition } });
    props.onEditOrder()
    // refresh();

  }

  return (
    // <Edit>
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Change position</span>
      </div>
      <Edit id={storeId} className="test" sx={{ width: 550 }} mutationMode="optimistic" >
        <SimpleForm onSubmit={offerEdit}>
          <NumberInput
            source="position"
            label="Order Id"
          />

        </SimpleForm>
      </Edit>
    </Wrapper>
  );
};

export default OfferOrder;