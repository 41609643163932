import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Link } from '@mui/material';

const MyUrlField = ({ source }) => {
    const record = useRecordContext();
    const newUrl = record[source].substring(0, 24);
    return record ? (
        <Link href={record[source]} sx={{ textDecoration: 'underline' }}>
            {newUrl}
            {/* <LaunchIcon sx={{ width: '0.5em', height: '0.5em', paddingLeft: 2 }} /> */}
        </Link>
    ) : null;
}

export default MyUrlField;



