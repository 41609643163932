import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  Button,
  DateField,
  DeleteWithConfirmButton,
  NumberField,
  useGetList,
  useRecordContext,
  useRedirect,
  useRefresh
} from "react-admin";
import Dialog from "@mui/material/Dialog";
import Wrapper from "../styles/categories/CategoryList";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CategoryCreate from "./CategoryCreate";
import CategoryOrder from "./CategoryOrder"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { CategoryEdit } from "./CategoryEdit";
import arrow from "../../assets/arrow.png"


export const CategoryList = () => {
  const [recordForEdit, setRecordForEdit] = React.useState(3);
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState(false);
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { data } = useGetList("stores", {
    pagination: { page: 1, perPage: 500 }
  }
  );

  const postRowStyle = (record, index) => ({// eslint-disable-next-line
    outline: record.id == recordForEdit.id ? '1px solid #ff6d44' : ''
  })

  const choice = data;

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => {
      redirect('/categories');
      refresh();
    }, 2200);
  }
  const editModal = () => {
    setEdit(false);
    setTimeout(() => {
      redirect('/categories');
      refresh();
    }, 2200);
  }
  const orderModal = () => {
    setOrder(false);
    setTimeout(() => {
      redirect('/categories');
      refresh();
    }, 1100);
    setTimeout(() => {
      setRecordForEdit(0)
    }, 1300
    )
  }

  const CreateCategoryButton = () => {
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => setOpen(true)}
        >Add New
          <AddIcon style={{
            marginLeft: "4px",
            marginTop: "2px",
            fontSize: "13px !important",
          }} />
        </Button>
      </>
    );
  };

  const EditOrderButton = ({ source }) => {
    const record = useRecordContext();
    const openInPopup = record => {
      setRecordForEdit(record)
      setOrder(true)
    }
    return (
      <>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => openInPopup(record)}
          style={{ marginTop: "0px" }}
        >
          <img src={arrow} alt="arrow" style={{ maxWidth: "15px" }} />

        </Button>
      </>
    );
  };


  const EditStoreButton = ({ source }) => {
    const record = useRecordContext();

    const openInPopup = (record) => {
      setRecordForEdit(record);
      setEdit(true);
    };
    return (
      <div>
        <Button
          color="primary"
          aria-label="create"
          onClick={() => {
            openInPopup(record);
          }}
        >
          <EditOutlinedIcon style={{ marginRight: "4px" }} />
        </Button>
      </div>
    );
  };

  return (
    <Wrapper>
      <List actions={<CreateCategoryButton />}      empty={false}  >
        <Datagrid rowStyle={postRowStyle} >
          <TextField source="position" label="Order Id" />
          <TextField source="name" />
          <ImageField
            source="iconUrl"
            sx={{
              maxWidth: 70,
              borderRadius: "10 10 0 0"
            }}
            title="title"
            label="Category Image"
          />
          <NumberField source="storeCount" label="Category Stores" />

          <DateField
            source="dateCreated"
            options={{
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            }}
            label="Date Added"
          />
          <EditOrderButton />
          <EditStoreButton label="Actions" />

          <DeleteWithConfirmButton
            icon={<CloseIcon />}
            label=""
            confirmContent="You will not be able to recover this record. Are you sure?"
          />

        </Datagrid>
      </List>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <CategoryCreate items={choice} onAddCategory={closeModal} />
      </Dialog>


      <Dialog open={edit} onClose={() => setEdit(false)}>
        <CategoryEdit recordForEdit={recordForEdit} items={choice}
          onEditCategory={editModal} />
      </Dialog>

      <Dialog open={order} onClose={() => setOrder(false)}>
        <CategoryOrder recordForEdit={recordForEdit} onEditOrder={orderModal} />
      </Dialog>


    </Wrapper>
  )
};




