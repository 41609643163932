import React, { useState, useEffect } from "react";
import UserService from "../../UserService";
import {
  Create,
  SimpleForm,
  useUpdate,
  AutocompleteInput
} from "react-admin";
import Wrapper from "../styles/most-visited/MostVisitedCreate";
import modal from "../../assets/modal.png";
import { stores_url } from "../../constants";

const MostVisitedCreate = (props) => {

  const [stores, setStores] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(stores_url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "authorization": "Bearer " + UserService.getToken()
          },
        });
        const json = await response?.json();
        let results = json;

        setStores(results);

      } catch (error) {
        console.log("error", error)
      }
    }
    fetchData();
  }, []);

  const storesArray = stores

  const [update] = useUpdate();
  const postSave = (data) => {
    console.log(data);
    const storeId = data.id;

    update("ordering/addStore", { id: storeId, data: { orderTypeId: 1, position: 2 } });
    props.onAddStore();


  };
  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Add Store</span>
      </div>
      <Create className="test" sx={{ width: 480 }} mutationMode="optimistic">
        <SimpleForm onSubmit={postSave}>
          <AutocompleteInput source="id" choices={storesArray} label="Name" />

        </SimpleForm>
      </Create>
    </Wrapper>
  );
};
export default MostVisitedCreate;
