import React from "react";
import {
  useUpdate,
  Button
} from "react-admin";
import Wrapper from "../styles/partners/DeletePartner";
import modal from "../../assets/modal.png";

const StoreEdit = (props) => {
  const { recordForEdit } = props;

  const [update] = useUpdate();

  const storeEdit = (data) => {

    update("stores/partners", {
      id: recordForEdit.id,
      data: { storeId: recordForEdit.id, partner: false },
      //   previousData: recordForEdit,
    });

    props.onDeleteStore();

  };

  return (
    // <Edit>
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>Delete Partner </span>
      </div>

      {/* <Form onSubmit={storeEdit} record={recordForEdit} className="old-image-form">
          
          {/* <BooleanInput source="isPartner" label="NewPay Partner" /> */}
      <div className="modal-content">
        <div>Are you sure?</div>
        <Button
          className="button-delete"
          color="primary"
          aria-label="create"
          onClick={storeEdit}
        >Delete
        </Button>
      </div>
      {/*           
        </Form> */}

    </Wrapper>
  );
};

export default StoreEdit;
