import { defaultTheme } from "react-admin";

const myTheme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,

    RaSidebar: {
      closed: {
        backgroundColor: "red",
      },
      fixed: {
        position: "inherit",
      },
    },
    RaDatagrid: {
      root: {
        backgroundColor: "Lavender",
        "& .RaDatagrid-headerCell": {
          backgroundColor: "MistyRose",
        },
      },
    },
    RaLayout: {
      content: {
        backgroundColor: "#333",
      },
    },
  },
};

export default myTheme;
