import React, { useState, useEffect } from "react";
import UserService from "../../UserService";
import {
  Create,
  SimpleForm,
  AutocompleteInput,
  useUpdate,
} from "react-admin";
import Wrapper from "../styles/partners/CreatePartner"
import modal from "../../assets/modal.png";
import { stores_url } from "../../constants";

const CreatePartner = (props) => {

  const arr = props.items;
  const storesFiltered = arr.filter((el) => el.isDeleted !== true && el.isVerified === true);

  const [stores, setStores] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(stores_url, {
          method: 'GET',
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
            "authorization": "Bearer " + UserService.getToken()
          },
        });
        const json = await response?.json();
        let results = json;

        setStores(results);

      } catch (error) {
        console.log("error", error)
      }
    }
    fetchData();
  }, []);

  const storesArray = stores
  console.log(storesArray)



  const [update] = useUpdate();
  const postSave = (data) => {
    let newStore = storesArray.find(x => x.id === data.name);
    console.log(newStore);


    update("stores", {
      id: newStore.id,
      data: { ...data, categories: newStore.categories, isPartner: true, logoBase64: '', logoExtension: '', name: newStore.name, url: newStore.url },
      // previousData: newStore,
    }
    );
    // update("stores/partners", {
    //   id: newStore.id,      
    //   data: {storeId: newStore.id, isPartner: false },
    // //   previousData: recordForEdit,
    // });



    props.onEditPartner();
  };

  return (
    <Wrapper>
      <div className="modal-top">
        <img src={modal} alt="modal" />
        <span>New Partner</span>
      </div>
      <Create className="test" sx={{ width: 480 }} redirect="list">
        <SimpleForm onSubmit={postSave}>


          <AutocompleteInput source="name" choices={storesFiltered}
            optionValue="id"
          />


        </SimpleForm>
      </Create>
    </Wrapper>
  );
};

export default CreatePartner;
