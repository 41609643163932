import * as React from "react";
import { MenuItemLink } from "react-admin";
import PercentIcon from "@mui/icons-material/Percent";

const OffersSubmenu = (props) => {
  return (
    <div>
      <MenuItemLink
        to="/offers"
        primaryText="View All"
        leftIcon={<PercentIcon />}
        sx={{
          color: "#fff",
          marginTop: 5,
          "& .RaMenuItemLink-icon": {
            color: "#fff",
          },
        }}
      />
    </div>
  );
};

export default OffersSubmenu;
