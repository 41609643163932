import { styled } from '@mui/system';

const Wrapper = styled('div')({
  color: 'darkslategray',
  backgroundColor: 'aliceblue',
  padding: 8,
  borderRadius: 4,
  borderCollapse: "separate",
  borderSpacing: 0,
  ".MuiTableRow-root":{  
  boxShadow: "0px 5px 10px 2px #dee5e7", 
  // backgroundColor: "#fff",
  borderRadius: 20,
},
".MuiTableRow-root td:nth-child(2)":{  
  borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,  
 },
".MuiTableRow-root td:last-child":{  
  borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    marginRight: 20
},
".css-rqglhn-MuiTable-root" : {  
  borderCollapse: "separate",
  borderSpacing: "0 10px"
},
".MuiPaper-root" : {
  backgroundColor: "transparent",
  boxShadow: "none"
},
".MuiTableCell-head" :{
  backgroundColor: "transparent !important",
  boxShadow: "none !important"
},
".MuiTableRow-head" :{
  backgroundColor: "transparent !important",
  boxShadow: "none !important"
},
".MuiTableRow-hover:hover" : {
  outline: "1px solid #ff6d44",
  backgroundColor: "transparent !important",
  color: "#BF2279"
},
".MuiTableRow-hover:hover td" : { 
  backgroundColor: "#fff !important",
  color: "#BF2279"
},
".MuiTableRow-root .MuiBox-root ":{ 
  maxHeight: 35,
  
},
".MuiTableRow-root .MuiBox-root img":{ 
  maxHeight: 26
},
".column-undefined":{
  maxWidth: "22px !important",
  padding: "0px !important"
},
".column-undefined button": {
  justifyContent: "flex-start",
  marginLeft: "3px",
 },
".column-undefined a span": {
  borderRight: "1px solid #BF2279"
},
".column-undefined a span svg": {
  marginRight: "3px"
},
".column-storeCount": {
  textAlign: "center"
},
".column-iconUrl": {
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  padding: 10,
  minHeight: "32px !important"
  // lineHeight: 1.2
}
 
 
});

export default Wrapper;