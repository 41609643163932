import { styled } from "@mui/system";

const Wrapper = styled("div")({
  ".MuiTextField-root": {
    width: 540,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden"
  },
  ".MuiTextField-root label": {
    position: "relative",
    transform: "none",
    fontSize: "14px !important",
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
  },
  ".RaLabeled-label span, .MuiFormControlLabel-label": {
    fontSize: "14px !important",
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
  },

  ".MuiFormControlLabel-root": {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    width: 285,
    marginLeft: 0,
  },
  ".MuiFilledInput-root:before": {
    borderBottom: "none",
  },
  ".MuiFilledInput-root input": {
    background: "#fff",
    border: "1px solid #c4c4c4",
    borderRadius: 41,
    padding: 10,
  },
  ".MuiFilledInput-root:hover, .MuiFilledInput-root:focus": {
    borderRadius: 41,
  },

  ".css-1r5okjt-RaCreate-root": {
    width: 550,
  },
  ".MuiInputLabel-root": {
    minWidth: 84,
  },
  ".RaLabeled-label": {
    width: 75,
  },
  ".css-1xuwtk8-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked": {
    color: "#fff !important",
    // backgroundColor:"#BF2279"
  },
  ".MuiSwitch-switchBase input[type=checkbox]": {
    color: "#BF2279 !important",
    // backgroundColor:"#BF2279"
  },
  "input[type=checkbox]:checked": {
    color: "#BF2279 !important",
    // backgroundColor:"#BF2279"
  },
  "input[type=checkbox]:checked:before": {
    color: "#BF2279 !important",
  },
  "input[type=checkbox i] ": {
    backgroundColor: "#BF2279",
  },
  ".RaToolbar-defaultToolbar .MuiButton-root": {
    backgroundColor: "#BF2279",
    borderRadius: 20,
    color: "#fff",
    textTransform: "capitalize",
  },
  ".RaToolbar-defaultToolbar ": {
    justifyContent: "flex-end !important",
    // backgroundColor: "#fff"
  },
  ".modal-top": {
    width: "550px !important",
    height: "auto",
    position: "relative",
  },
  ".modal-top img": {
    width: "100%",
    height: "auto",
  },
  ".modal-top span": {
    position: "absolute",
    top: 15,
    left: 38,
    color: "#fff",
    fontWeight: 500,
    fontSize: 16,
  },
  ".MuiInputBase-root:before": {
    borderBottom: "none",
  },
  ".MuiFilledInput-root input::after": {
    borderBottom: "1px solid #c4c4c4",
  },
  ".css-i6tejy-MuiInputBase-root-MuiFilledInput-root:after": {
    borderBottom: "none",
  },
  ".css-m8l961-MuiInputBase-root-MuiFilledInput-root:after": {
    borderBottom: "none",
  },
  "textarea": {
    marginRight: "-10px !important",
    width: "260px !important",
    padding: '10px !important'
  },

  ".css-1xuwtk8-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
  {
    backgroundColor: "#BF2279",
    opacity: 1,
  },
  ".MuiSwitch-thumb": {
    width: 12,
    height: 12,
    marginTop: 4,
    marginLeft: 4,
  },
  ".RaFileInput-preview": {
    marginLeft: 20,
  },
  ".background-field img": {
    maxHeight: 104
  },
  ".MuiInputBase-root:hover": {
    borderBottom: "none"
  },
  ".MuiInputBase-root.MuiFilledInput-root:hover:not(.Mui-disabled):before": {
    borderBottom: "none"
  },

  ".RaAutocompleteInput-textField .MuiFilledInput-root": {
    width: 285,
    padding: "0 !important",
    marginRight: 45
  },
  ".RaAutocompleteInput-textField .MuiFilledInput-root input": {
    padding: "10px !important"
  },
  ".RaAutocompleteInput-textField p": {
    display: "none"
  },
  ".RaAutocompleteInput-textField .MuiFilledInput-root:after": {
    borderBottom: "none !important"
  },

  ".create-page": {
    width: 550
  },

  ".MuiAutocomplete-inputRoot:hover": {
    backgroundColor: "#fff !important"
  },

  ".RaFileInput-preview img": {
    height: 40,
    width: "auto !important"
  },

  ".logo-field": {
    display: "flex",
    flexDirection: "row",
    marginTop: 14
  },
  ".logo-field img": {
    maxWidth: 80,
    maxHeight: 40
  },
  ".logo-field-label": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
    alignItems: "flex-start",
    flexDirection: "row",
    minHeight: "110px !important"
  },
  ".logo-field-label p": {
    width: 233,
  },
  ".background-field": {
    width: "auto",
    height: 120,
    // border: "1px solid #ebebeb",
    borderRadius: 10
  },
  ".css-1hatj2z.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#BF2279 !important"
  },
  ".MuiToolbar-root": {
    paddingBottom: "20px !important"
  },
  ".MuiFilledInput-root": {
    background: "#fff",
    marginLeft: "95px !important",
    width: "282px !important",
    marginRight: "-35px !important",
  },
  ".modal-content": {
    textAlign: "center",
    paddingTop: 20,
  },
  ".modal-text": {
    paddingTop: 40,
    paddingBottom: 20
  },
  "button": {
    color: "#fff",
    backgroundColor: "#bf2279",
    fontSize: "12",
    fontWeight: 500,
    padding: "6px 30px",
    borderRadius: 24,
    textTransform: "capitalize",
    marginBottom: 20,
    marginLeft: 340,
    marginTop: 20
  },
  ".Mui-disabled": {
    color: "#fff",
    backgroundColor: "#d36ca5",

  },
  ".MuiDialog-paper": {
    maxWidth: "540px !important",
    overflow: "hidden"
  },
  ".MuiButton-root:hover": {
    backgroundColor: "#bf2279",
  }
});

export default Wrapper;